import { Injectable } from '@angular/core';

@Injectable()
export class Base64Encoder {
  

  private decode(base64String){
    return atob(base64String);
  }

  private toByteArray(data){
    return new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
  }

  getPDFBlob(base64String){
    var decoded = this.decode(base64String);
    return new Blob([this.toByteArray(decoded)], {type: 'application/pdf'});
  }
}
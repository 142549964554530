import { Component, OnInit, HostListener, Input } from '@angular/core';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';
import { Role } from 'src/app/core/models/role';
import { CartService } from 'src/app/core/tools/cart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() showLarge: boolean;

  isShowLogin = false;
  isShowCart = false;

  nav;

  currentUser;
  role;

  cartSize = 0;

  facebookUrl = "https://www.facebook.com/paneramx/";
  twitterUrl = "https://twitter.com/lapanera";
  instagramUrl = "https://www.instagram.com/paneramx/";
  rappiUrl = "https://www.rappi.com.mx/restaurantes/la-panera";
  sindeUrl = "https://www.sindelantal.mx/delivery/miguel-hidalgo-df/la-panera-militar/";

  isHome = false;

  isLiteMode = environment.liteMode;

  constructor(private auth: LocalAuthService, private cart: CartService) {
    this.currentUser = this.auth.currentUserValue;
    this.role = Role;
  }

  ngOnInit() {
    this.reloadCart();

    this.getCartSize();

    this.nav = document.querySelector('#navbar-custom');
    this.checkIsLarge();
  }

  reloadCart() {
    this.cart.cartObservable.subscribe(
      () => this.getCartSize()
    );
  }

  getCartSize() {
    this.cartSize = this.cart.getCartSize();
  }

  checkIsLarge() {
    if (this.showLarge) {
      this.nav.classList.add('large');
      this.isHome = true;
      return;
    }
    this.nav.classList.remove('large');
    this.isHome = false;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {

    if (window.pageYOffset > this.nav.clientHeight) {
      this.nav.classList.add('scrolled');
      // nav.classList.remove('top');
      // fabNetworks.classList.add('scrolled');

      // this.navLogoUrl = this.logoUrl;

    } else {
      this.nav.classList.remove('scrolled');
      // nav.classList.add('top');
      // fabNetworks.classList.remove('scrolled');

      // this.navLogoUrl = this.whiteLogoUrl;
    }
  }

  toggleLogin() {
    this.isShowLogin = !this.isShowLogin;
  }

  logout() {
    this.auth.logout(this.isHome);
  }

  toggleCart(event?) {
    if (event != undefined) {
      if (event) {
        this.isShowCart = !this.isShowCart;
        this.isShowLogin = true;

        return;
      }

      this.isShowCart = !this.isShowCart;
      return;
    }
    this.isShowCart = !this.isShowCart;
  }

  goToFacebook() {
    window.open(this.facebookUrl, "_blank");
  }

  goToTwitter() {
    window.open(this.twitterUrl, "_blank");
  }

  goToInstagram() {
    window.open(this.instagramUrl, "_blank");
  }

  goToRappi() {
    window.open(this.rappiUrl, "_blank");
  }
  goToSinde() {
    window.open(this.sindeUrl, "_blank");
  }
}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class UsersAdminUseCases {
    constructor(private reloadService:ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    edit(product) {
        this.postService.editData(product, Endpoints.usersPrivate);
    }

    retrieveUsers() {
        var result = this.getService.retrieveData(`${Endpoints.usersPrivate}`);
        result.subscribe(
            (result) => this.setUsersData(result)
        );
    }
    private setUsersData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateUsersList(res.data);
    }
}   
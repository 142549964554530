import { Role } from "./role";

export class User {
    id: number;
    name: string;
    password?: string;
    firstName: string;
    lastName: string;
    rol: Role;
    email: string;
    token?: string;
    isSocialLogin: boolean;
}
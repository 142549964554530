import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProductsUseCases } from './use-cases/products-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { CartService } from 'src/app/core/tools/cart.service';
import { trigger, state, style, transition, animate, stagger, query } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Product } from 'src/app/core/models/product';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/core/tools/globals.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductsUseCases, Product],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
    trigger('openCloseMobileMenu', [
      state('open', style({
        'width': '70vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '-70vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '-70vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '70vw',
            'left': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparent', [
      state('open', style({
        'width': '30vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-30vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-30vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '30vw',
            'right': '0'
          }),
        ),
      ]),
    ])
  ],
})
export class ProductsComponent implements OnInit, OnDestroy {

  productsList = [];
  categoriesSelect = new FormControl();
  categoriesList = [];

  searchingProducts = false;

  searchFilter = "";

  categoriesToFilter = [];

  target = document.querySelector("body");

  showModal: boolean = false;

  selectedProduct: Product = new Product();
  imageProduct = "";

  getSub = new Subscription();
  categoriesSub = new Subscription();
  productsSub = new Subscription();
  deliverySub = new Subscription();

  filtersForm: FormGroup;

  showMobileMenu = false;

  isMobile = false;

  isReset = false;

  selectedCategory = "";

  isLiteMode = environment.liteMode;

  constructor(private builder: FormBuilder,
    private route: ActivatedRoute,
    private cart: CartService,
    private reloadService: ReloadService,
    private dataService: DataService,
    private useCases: ProductsUseCases,
    private globals: Globals) { }

  ngOnInit() {

    this.checkIsMobile();

    this.setValidators();

    this.reloadData();

    this.getSelectedData();

    this.useCases.retrieveDeliveryRestrictions();
    this.getDeliveryRestrictions();

    this.useCases.retrieveCategories();
    this.getCategories();

    this.searchingProducts = true;

    this.getProducts();
  }

  ngOnDestroy() {
    this.getSub.unsubscribe();
    this.categoriesSub.unsubscribe();
    this.productsSub.unsubscribe();
    this.deliverySub = new Subscription();
  }

  checkIsMobile() {
    if (window.innerWidth <= 992) {
      this.isMobile = true;
    }
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );
  }

  onGetSuccess() {
    this.searchingProducts = false;
  }

  getSelectedData() {
    this.categoriesToFilter = [];
    var category = this.route.snapshot.paramMap.get('category');
    var name = this.route.snapshot.paramMap.get('name');

    if (category != null || category != undefined) {
      this.selectedCategory = name;
      this.categoriesToFilter.push(category);
      this.useCases.retrieveProductsByCategory(this.categoriesToFilter);
    }
    else {
      this.useCases.retrieveProducts();
    }
  }

  getCategories() {
    this.categoriesSub = this.dataService.currentCategoriesList.subscribe((data) => {
      this.setFilters(data);
    });
  }

  getProducts() {
    this.productsSub = this.dataService.currentProductsList.subscribe((data) => {
      this.productsList = this.formatProducts(data);
    });
  }

  formatProducts(products) {
    products.forEach(element => {
      element.quantity = 1;
    });

    return products;
  }

  increaseProductQuantity(product) {
    product.quantity++;
  }

  decreaseProductQuantity(product) {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }

  toggleModal(isOpening, data?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (data != undefined) {
        this.setProductData(data);
      }

      return;
    }
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  setProductData(data) {
    this.selectedProduct = data;
    this.imageProduct = this.selectedProduct.imageLinks[0];
  }

  addProductToCart(product, isModal) {
    if (isModal) {
      this.toggleModal(false);
    }
    var productToAdd = Object.assign({}, product);
    this.cart.addProduct(productToAdd);
    product.quantity = 1;
  }

  changeImage(image) {
    this.imageProduct = image;
  }

  setFilters(data) {
    data.forEach(element => {
      this.addFilterElement(element);
    });

    this.checkFilters();
  }

  setFilterElement(data) {
    return this.builder.group({
      active: [data.active],
      id: [data.id],
      linkImage: [data.linkImage],
      name: data.name,
      weight: [data.weight],
      selected: [false]
    });
  }

  addFilterElement(data) {
    const control = <FormArray>this.filtersForm.controls['filters'];
    control.push(this.setFilterElement(data));
  }

  setValidators() {
    this.filtersForm = this.builder.group({
      filters: this.builder.array([]),
    });
  }

  checkFilters() {
    const control = <FormArray>this.filtersForm.controls['filters'];
    control.controls.forEach(item => {
      var id = item.get('id').value;
      var res = this.categoriesToFilter.filter(item => (item == id));
      if (res.length > 0) {
        item.get('selected').setValue(true);
      }
    });
  }

  filterChanges(e, control) {
    this.clearFilters();
    control.get('selected').setValue(true);

    this.selectedCategory = control.get('name').value;

    this.categoriesToFilter.push(control.get('id').value);
    this.productsList = [];
    this.searchingProducts = true;
    this.useCases.retrieveProductsByCategory(this.categoriesToFilter);
  }

  resetFilters(){
    this.clearFilters();

    this.selectedCategory = "";

    this.productsList = [];
    this.searchingProducts = true;
    this.useCases.retrieveProducts();
  }

  clearFilters() {
    this.isReset = true;
    this.categoriesToFilter = [];
    const control = <FormArray>this.filtersForm.controls['filters'];
    control.controls.forEach(item => {
      if (item.get('selected').value) {
        item.get('selected').setValue(false);
      }
    });
  }

  getDeliveryRestrictions() {
    this.deliverySub = this.dataService.currentDeliveryRestrictions.subscribe((data) => this.saveDeliveryRestrictions(data[0]));
  }

  saveDeliveryRestrictions(data) {
    this.globals.setDeliveryRestrictions(data);
  }

  // showMobileMenu(){
  //   this.showMobileMenu = true;
  // }
}

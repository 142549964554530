export enum Endpoints {
    login = 'api/public/auth/',
    logout = 'api/public/logout/',
    googleLogin = 'api/public/auth/google/',
    facebookLogin = 'api/public/auth/facebook/',
    usersPrivate = 'api/secure/users/',
    usersPublic = 'api/public/users/',
    categoriesPrivate = 'api/secure/categories/',
    categoriesPublic = 'api/public/categories/',
    productsPrivate = 'api/secure/products/',
    productsDelete = 'api/secure/products/delete/',
    productsPublic = 'api/public/products/',
    productsPrivateVisibility = 'api/secure/products/enable/',
    companyPrivate = 'api/secure/company/',
    companyPublic = 'api/public/company/',
    documents = "api/public/documents/",
    documentsPrivate = "api/secure/documents/",
    carouselPrivate = 'api/secure/carousel/',
    carouselPublic = 'api/public/carousel/',
    carouselPrivateEdit = 'api/secure/carousel/image/',
    orderPrivatePost = 'api/secure/register/order/online/',
    orderPrivateGet = 'api/secure/register/order/online',
    orderPrivateClientGet = 'api/secure/register/order/online/client',
    orderExcelPrivate = 'api/secure/order/online/production/report',
    orderPDFPrivate = 'api/secure/order/online/pdf',
    orderStatusPrivate = 'api/secure/register/order/status/',
    orderStatusUpdate = 'api/secure/order/online/status',
    orderRecurrentPrivate = 'api/secure/order/recurrent/',
    orderRefundPrivate = 'api/secure/order/online/refund/',
    localOrderPrivatePost = 'api/secure/register/order/local/',
    deliveryRestrictions = 'api/public/delivery/restrictions/',
    recurrentOrder = 'api/secure/register/order/recurrent/',
    recurrentOrderStatus = 'api/secure/register/order/recurrent/status',
    recurrentOrderPrivateClientGet = 'api/secure/register/order/recurrent/user',
    periodSubscriptions = 'api/secure/register/period/subscription',
    usersPrivateReport = 'api/secure/users/report/',
    statesPublic = 'api/public/states/',
    postalCodesPrivate = 'api/secure/register/postal/code/',
    requestChangePassword = 'api/public/email/password',
    updatePassword = 'api/public/password/'
}
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchUsers'
})
export class UsersFilterPipe implements PipeTransform {
    transform(value: any, searchValue): any {
        if (!searchValue) return value;
        return value.filter((v) =>
            (v.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (v.firstName.toLowerCase().includes(searchValue.toLowerCase())) ||
            (v.lastName.toLowerCase().includes(searchValue.toLowerCase())) ||
            (v.email.toLowerCase().includes(searchValue.toLowerCase()))
        );
    }
}
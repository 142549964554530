import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './common/navbar/navbar.component';
import { HomeComponent } from './common/home/home.component';
import { AboutComponent } from './common/about/about.component';
import { CarouselComponent } from './common/carousel/carousel.component';
import { GetService } from './core/httpServices/get.service';
import { PostService } from './core/httpServices/post.service';
import { AESService } from './core/tools/aes';
import { LocalAuthService } from './core/authServices/auth.service';
import { DataService } from './core/dataServices/data.service';
import { ReloadService } from './core/dataServices/reload.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { ProductsComponent } from './common/products/products.component';

import { MatInputModule, MatButtonModule, MatTooltipModule, MatProgressBarModule, MatPaginatorModule, MatCheckboxModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule, MatProgressSpinnerModule, MatDividerModule, MatSidenavModule, MatListModule, MatExpansionModule, MatCardModule, MatTabsModule, MatGridListModule, MatSlideToggleModule, MatBadgeModule, MatStepperModule, MatChipsModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { Globals } from './core/tools/globals.service';
import { ProductsAdministrationComponent } from './administration/products-administration/products-administration.component';
import { LoginComponent } from './common/login/login.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { RegexService } from './core/tools/regex.service';
import { ClientRegisterComponent } from './client/client-register/client-register.component';
import { ClientProfileComponent } from './client/client-profile/client-profile.component';
import { CategoriesAdministrationComponent } from './administration/categories-administration/categories-administration.component';
import { DeleteDialog } from './common/dialog/delete-dialog';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeRecommendationsComponent } from './common/home-recommendations/home-recommendations.component';

import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData, DatePipe, CurrencyPipe } from '@angular/common';
import { Base64Decoder } from './core/tools/base64-decoder.service';
import { Base64Encoder } from './core/tools/base64-encoder.service';
import { ConfirmDialog } from './administration/products-administration/dialog/confirm-dialog';
import { CarouselAdministrationComponent } from './administration/carousel-administration/carousel-administration.component';
import { CategoriesFilterPipe } from './core/pipes/searchCategories.pipe';
import { ProductsFilterPipe } from './core/pipes/search-products.pipe';
import { ShowImagesPipe } from './core/pipes/show-images.pipe';
import { ShowImagePipe } from './core/pipes/show-image.pipe';
import { HomeInfoComponent } from './common/home-info/home-info.component';
import { SidenavComponent } from './administration/sidenav/sidenav.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ClientCartComponent } from './client/client-cart/client-cart.component';
import { CartService } from './core/tools/cart.service';
import { ShowTotalPipe } from './core/pipes/show-total.pipe';
import { ProductsFilterByCategoryPipe } from './core/pipes/filter-products.pipe';
import { FooterComponent } from './common/footer/footer.component';
import { CheckoutComponent } from './client/checkout/checkout.component';
import { DeleteWithObjectDialog } from './common/dialog-delete-with-object/delete-dialog';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TermsConditionsComponent } from './common/terms-conditions/terms-conditions.component';

import { NgxStripeModule } from 'ngx-stripe';
import { PDFViewerComponent } from './common/pdf-viewer/pdf-viewer.component';
import { OrdersAdministrationComponent } from './administration/orders-administration/orders-administration.component';
import { ExcelGeneratorService } from './core/tools/excel-generator.service';
import { OrdersFilterPipe } from './core/pipes/search-orders.pipe';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { ShowFullTotalPipe } from './core/pipes/show-full-total.pipe';
import { NavbarAdminComponent } from './administration/navbar-admin/navbar-admin.component';
import { UsersAdministrationComponent } from './administration/users-administration/users-administration.component';
import { UsersFilterPipe } from './core/pipes/search-users.pipe';
import { ExcelReportService } from './core/tools/excel-report.service';
import { ReportsAdministrationComponent } from './administration/reports-administration/reports-administration.component';
import { CompanyAdministrationComponent } from './administration/company-administration/company-administration.component';
import { DocumentsAdministrationComponent } from './administration/documents-administration/documents-administration.component';
import { PurchaseConfirmationComponent } from './client/purchase-confirmation/purchase-confirmation.component';
import { ProfileDetailsComponent } from './client/profile-details/profile-details.component';
import { ExcelRecurrentReportService } from './core/tools/excel-recurrent-report.service';
import { ConfirmCancelDialog } from './administration/orders-administration/dialog/confirm-cancel-dialog';
import { ShowStatusPipe } from './core/pipes/show-status.pipe';
import { CancelRecurrentDialog } from './common/dialog-cancel-recurrent/cancel-recurrent-dialog';
import { ShowRecurrentStatusPipe } from './core/pipes/show-recurrent-status.pipe';
import { ChangePasswordComponent } from './common/change-password/change-password.component';
import { ToastrModule } from 'ngx-toastr';
import { HomeMessageComponent } from './common/home-message/home-message.component';

registerLocaleData(localeEs);

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("98525743657-cqkena06usdfvra3bhrbsr1p7h2iu8gf.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("326059005103869")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    CarouselComponent,
    ProductsComponent,
    ProductsAdministrationComponent,
    LoginComponent,
    ClientRegisterComponent,
    ClientProfileComponent,
    CategoriesAdministrationComponent,
    DeleteDialog,
    ConfirmDialog,
    HomeRecommendationsComponent,
    CarouselAdministrationComponent,
    CategoriesFilterPipe,
    ProductsFilterPipe,
    ShowImagesPipe,
    ShowImagePipe,
    HomeInfoComponent,
    SidenavComponent,
    ClientCartComponent,
    ShowTotalPipe,
    ProductsFilterByCategoryPipe,
    FooterComponent,
    CheckoutComponent,
    DeleteWithObjectDialog,
    TermsConditionsComponent,
    PDFViewerComponent,
    OrdersAdministrationComponent,
    OrdersFilterPipe,
    NotFoundComponent,
    ShowFullTotalPipe,
    NavbarAdminComponent,
    UsersAdministrationComponent,
    UsersFilterPipe,
    ReportsAdministrationComponent,
    CompanyAdministrationComponent,
    DocumentsAdministrationComponent,
    PurchaseConfirmationComponent,
    ProfileDetailsComponent,
    ConfirmCancelDialog,
    ShowStatusPipe,
    CancelRecurrentDialog,
    ShowRecurrentStatusPipe,
    ChangePasswordComponent,
    HomeMessageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), 
    MatExpansionModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    PasswordStrengthMeterModule,
    CarouselModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    SocialLoginModule,
    MatBadgeModule,
    MatStepperModule,
    PdfViewerModule,
    NgxStripeModule.forRoot('pk_live_51H9bPaLjIgmHkHfwBvKknKFz3FIeKCeVXxWYP3S7QCPQum6qWMcL7AODGwnwflvO5p3mOZxsaXbr2nIUYuW9rMHz00XRyrvsKk'),
    MatSnackBarModule,
    MatChipsModule,
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: LOCALE_ID, useValue: "es-MX" },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    GetService,
    PostService,
    AESService,
    LocalAuthService,
    DataService,
    ReloadService,
    Globals,
    RegexService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    Base64Decoder,
    Base64Encoder,
    CartService,
    ShowTotalPipe,
    ExcelGeneratorService,
    DatePipe,
    CurrencyPipe,
    ExcelReportService,
    ExcelRecurrentReportService,
    ShowFullTotalPipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteDialog, ConfirmDialog, DeleteWithObjectDialog, ConfirmCancelDialog, CancelRecurrentDialog]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class CompanyUseCases {
    constructor(private reloadService: ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    edit(company) {
        this.postService.editData(company, Endpoints.companyPrivate);
    }

    editDeliveryRestrictions(company) {
        this.postService.editData(company, Endpoints.deliveryRestrictions);
    }

    retrieveDeliveryRestrictions() {
        var result = this.getService.retrieveData(Endpoints.deliveryRestrictions);
        result.subscribe(
            (result) => this.setDeliveryRestrictionsData(result)
        );
    }
    private setDeliveryRestrictionsData(res) {
        this.dataService.updateDeliveryRestrictions(res.data);
    }


    retrieve() {
        var result = this.getService.retrieveData(Endpoints.companyPublic);
        result.subscribe(
            (result) => this.setContentData(result)
        );
    }
    private setContentData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateCompanyData(res.data);
    }
}   
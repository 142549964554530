import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'width': '20vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '-20vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '-20vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '20vw',
            'left': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparent', [
      state('open', style({
        'width': '80vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-80vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-80vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '80vw',
            'right': '0'
          }),
        ),
      ]),
    ])
  ],
})
export class SidenavComponent implements OnInit, OnChanges {

  @Output() public onClose: EventEmitter<any> = new EventEmitter();
  @Input() show: boolean;

  showSidebav: boolean = false;

  target = document.querySelector("body");

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.show) {
      disableBodyScroll(this.target);
    }
  }

  hide(): void {
    enableBodyScroll(this.target);
    this.onClose.emit(false);
  }
}

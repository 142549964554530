import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchProducts'
})
export class ProductsFilterPipe implements PipeTransform {
    transform(value: any, searchValue): any {
        if (!searchValue) return value;
        return value.filter((v) =>
            (v.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (v.category.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (v.description.toLowerCase().includes(searchValue.toLowerCase()))
        );
    }
}
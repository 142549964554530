import { Injectable } from '@angular/core';

@Injectable()
export class RegexService {

    public addressNumberRegex = "^[0-9]{1,5}$";
    public pcRegex = "^[0-9]{5}$";
    public nameRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'.:;()%,& - 0-9-]{2,50}$";
    public addressRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'#:.,0-9 -]{5,100}$";
    public longCommentsRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'!¡¿?@#%()*_=+\"\"''\/\\$.,0-9\n\r -]{5,500}$";
    public commentsRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'!¡¿?@#%*_=()+\"\"''\/\\$.,0-9\n\r -]{5,300}$";
    public shortCommentsRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'!¡¿?@#%*_=+()\"\"''\/\\$.,0-9\n\r -]{5,150}$";
    public shortDescriptionRegex = "^[a-zA-ZñÑáÁÉéÍíÓóÚúü'!¡¿?@#%*_=()+;\"\"''\/\\$.,0-9\n\r -]{3,50}$";
    public phonePattern = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$";
    // public CURPRegex = "^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$"
    public CURPRegex = "^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$";
    public passwordRegex = "[a-zA-Z0-9 = .,!#$%&()*+\/=?@_]{6,20}$";
    public currencyRegex = '^([0-9]{3}|[0-9]+)(\.[0-9][0-9])?$';

    constructor() {

    }

    validateRegex(string, regex) {
        string = String(string);

        if (!string.match(regex)) {
            return null;
        } else {
            return "ok";
        }
    }
}
import { Injectable } from '@angular/core';
import { AESService } from './aes';

@Injectable()
export class Globals {
  role: string = 'test';

  url = "https://api.lapanera.com.mx:8443/";
  urlLocal = "http://localhost:8080/";

  userKey = 'user';

  deliveryRestrictionsKey = "delivery";

  constructor(private aes: AESService) {

  }

  getURL() {
    return this.url;
  }

  getUser() {
    var user = localStorage.getItem(this.userKey);
    if (user != null) {
      var json = JSON.parse(this.aes.decrypt(user));
      delete json.token;
      return json;
    }
    return null;
  }

  setUser(data) {
    var user = localStorage.getItem(this.userKey);
    if (user != null) {
      var json = JSON.parse(this.aes.decrypt(user));
      json.name = data.name;
      json.firstName = data.firstName;
      json.lastName = data.lastName;

      localStorage.setItem(this.userKey, this.aes.encrypt(JSON.stringify(json)));
    }
  }

  getUserData() {
    var data = this.getUser();
    delete data.isSocialLogin;
    delete data.rol;
    return data;
  }

  setDeliveryRestrictions(data) {
    localStorage.setItem(this.deliveryRestrictionsKey, this.aes.encrypt(JSON.stringify(data)));
  }

  getDeliveryRestrictions() {
    var restrictions = localStorage.getItem(this.deliveryRestrictionsKey);
    if (restrictions != null) {
      var json = JSON.parse(this.aes.decrypt(restrictions));
      delete json.token;
      return json;
    }
    return null;
  }
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ReportsAdminUseCases } from './use-cases/reports-admin-use-cases';
import { ExcelReportService } from 'src/app/core/tools/excel-report.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ExcelRecurrentReportService } from 'src/app/core/tools/excel-recurrent-report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reports-administration',
  templateUrl: './reports-administration.component.html',
  styleUrls: ['./reports-administration.component.scss'],
  providers: [ReportsAdminUseCases],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class ReportsAdministrationComponent implements OnInit, OnDestroy {

  excelForm: FormGroup;

  showModal = false;

  target = document.querySelector("body");

  reportOneSub = new Subscription();
  reportTwoSub = new Subscription();

  constructor(private builder: FormBuilder, private recurrentReport: ExcelRecurrentReportService, private report: ExcelReportService, private dataService: DataService, private useCases: ReportsAdminUseCases) { }

  reportData = [];

  ngOnInit() {
    enableBodyScroll(this.target);
    this.setValidators();

    this.getReportData();

    this.getRecurrentOrdersReportData();
  }

  ngOnDestroy() {
    this.reportOneSub.unsubscribe();
    this.reportTwoSub.unsubscribe();
  }

  getReportData() {
    this.reportOneSub = this.dataService.currentUsersReport.subscribe((data) => {
      this.report.saveExcelFile(data);
    })
  }

  generateOrdersByUserReport() {
    this.useCases.generateUsersReport();
  }

  toggleModal(isOpening, type?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      // this.updateType = type;

      return;
    }
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  generateNewReport(form) {
    var valid = form.valid;
    var value = form.getRawValue();

    if (valid) {
      this.useCases.generateRecurrentOrdersReport(this.formatBeginDate(value.startDate), this.formatEndDate(value.endDate));
    }
  }

  getRecurrentOrdersReportData() {
    this.reportTwoSub = this.dataService.currentRecurrentOrdersReport.subscribe((data) => {
      this.recurrentReport.saveExcelFile(data)
    })
  }

  setValidators() {
    this.excelForm = this.builder.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required]
    });
  }

  formatBeginDate(timestamp) {
    var date = new Date(timestamp);
    date.setHours(0, 0, 0);
    return date.getTime();
  }

  formatEndDate(timestamp) {
    var date = new Date(timestamp);
    date.setHours(23, 59, 59);
    return date.getTime();
  }
}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class CarouselUseCases {
    constructor(private reloadService:ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    add(image) {
        this.postService.editData(image, Endpoints.carouselPrivate);
    }

    delete(image){
        this.postService.deleteUsingBody(image,Endpoints.carouselPrivateEdit);
    }

    retrieve() {
        var result = this.getService.retrieveData(Endpoints.carouselPublic);
        result.subscribe(
            (result) => this.setData(result)
        );
    }
    private setData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateCarouselImages(res.data);
    }
}   
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class Base64Decoder {

  constructor(private sanitize: DomSanitizer) { }


  private decode(base64String) {
    return atob(base64String);
  }

  private toByteArray(data) {
    return new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
  }

  getBlop(base64String) {
    var decoded = this.decode(base64String);
    return new Blob([this.toByteArray(decoded)]);
  }

  getPDFBlob(base64String) {
    var decoded = this.decode(base64String);
    return new Blob([this.toByteArray(decoded)], { type: 'application/pdf' });
  }

  getImageURL(string) {
    var url = URL.createObjectURL(this.getBlop(string));
    return this.sanitize.bypassSecurityTrustUrl(url);
  }

  getPDFURL(string) {
    var url = URL.createObjectURL(this.getPDFBlob(string));
    return url;
  }
}
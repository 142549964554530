import { Component, OnInit } from '@angular/core';
import { FooterUseCases } from './use-cases/footer-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
import * as FileSaver from 'file-saver';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [FooterUseCases]
})
export class FooterComponent implements OnInit {

  pdfUrl;

  pdfBlob;

  showDocument: boolean = false;

  companyData;

  documents;

  facebookUrl = "https://www.facebook.com/paneramx/";
  twitterUrl = "https://twitter.com/lapanera";
  instagramUrl = "https://www.instagram.com/paneramx/";

  target = document.querySelector("body");

  currentYear = new Date(Date.now()).getFullYear();

  constructor(private decoder: Base64Decoder, private dataService: DataService, private useCases: FooterUseCases) { }

  ngOnInit() {
    this.useCases.retrieveCompanyData();
    this.getCompanyData();

    this.useCases.retrieveDocuments();
    this.getDocuments();
  }

  getCompanyData() {
    this.dataService.currentCompanyData.subscribe((data) => this.companyData = data[0]);
  }

  getDocuments() {
    this.dataService.currentDocuments.subscribe((data) => this.documents = data);
  }

  toggleDocument(type?) {
    if (type != undefined) {
      switch (type) {
        case 1: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.privacy);
          this.pdfBlob = this.decoder.getPDFBlob(this.documents.privacy);

          break;
        }
        case 2: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.termsAndConditions);
          this.pdfBlob = this.decoder.getPDFBlob(this.documents.termsAndConditions);

          break;
        }
        case 3: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.cancellationPolicies);
          this.pdfBlob = this.decoder.getPDFBlob(this.documents.cancellationPolicies);

          break;
        }
      }
      disableBodyScroll(this.target);
      this.showDocument = !this.showDocument;

      return;
    }
    enableBodyScroll(this.target);
    this.showDocument = !this.showDocument;
  }

  downloadDocument() {
    FileSaver.saveAs(this.pdfBlob, "document.pdf");
  }

  goToFacebook() {
    window.open(this.facebookUrl, "_blank");
  }

  goToTwitter() {
    window.open(this.twitterUrl, "_blank");
  }

  goToInstagram() {
    window.open(this.instagramUrl, "_blank");
  }

}

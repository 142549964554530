import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    order:any
}

@Component({
    selector: 'confirm-cancel-dialog',
    templateUrl: 'confirm-cancel-dialog.html',
})
export class ConfirmCancelDialog {

    constructor(
        public dialogRef: MatDialogRef<ConfirmCancelDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Globals } from 'src/app/core/tools/globals.service';
import { ClientProfileUseCases } from './use-cases/client-profile-use-cases';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Observable, Subscription } from 'rxjs';
import { CancelRecurrentDialog } from 'src/app/common/dialog-cancel-recurrent/cancel-recurrent-dialog';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss'],
  providers: [ClientProfileUseCases],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class ClientProfileComponent implements OnInit, OnDestroy {

  ordersList = [];

  recurrentOrdersList = [];

  activeOrdersList: Observable<any>;

  categoriesSelect = new FormControl();

  editForm: FormGroup;

  showModal: boolean = false;
  showInfoModal: boolean = false;
  selectedOrder;

  isFirstTime: boolean = false;

  target = document.querySelector("body");

  user: any;

  showUserDetails = false;

  selectedOrderType;

  searchingOrders = false;

  cancelModalSub = new Subscription();
  editSub = new Subscription();
  normalSub = new Subscription();
  recurrentSub = new Subscription();
  // cancelModalSub = new Subscription();

  constructor(private dataService: DataService,
    private reloadService: ReloadService,
    private useCases: ClientProfileUseCases,
    private globals: Globals,
    private regex: RegexService,
    private builder: FormBuilder,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.reloadData();

    this.setValidators();

    this.getUserData();

    this.useCases.retrieveOrders(this.user.email);
    this.getOrders();

    this.useCases.retrieveRecurrentOrders(this.user.id);
    this.getRecurrentOrders();
  }

  ngOnDestroy() {
    this.cancelModalSub.unsubscribe();
    this.editSub.unsubscribe();
    this.normalSub.unsubscribe();
    this.recurrentSub.unsubscribe();
  }

  reloadData() {
    this.editSub = this.reloadService.confirmationEditReturningObjectSuccess.subscribe(
      (data) => this.onEditSuccess(data)
    );

    this.editSub = this.reloadService.confirmationEditWithoutBodySuccess.subscribe(
      (data) => this.onCancelSuccess()
    );
  }

  onEditSuccess(data) {
    this.toggleModal(false, false);
    this.globals.setUser(data)
    this.getUserData();
  }

  onCancelSuccess() {
    this.useCases.retrieveRecurrentOrders(this.user.id);
  }

  getUserData() {
    this.user = this.globals.getUser();
    if (this.user.name == "" && this.user.firstName == "" && this.user.lastName == "") {
      this.toggleModal(true, true, this.user);
    }
  }

  getOrders() {
    this.normalSub = this.dataService.currentClientOrders.subscribe((data) => this.ordersList = data);
  }

  getRecurrentOrders() {
    this.recurrentSub = this.dataService.currentClientRecurrentOrders.subscribe((data) => {
      this.recurrentOrdersList = data;
    });
  }

  toggleInfoModal(isOpening, data?, type?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showInfoModal = !this.showInfoModal;

      if (data != undefined) {
        this.selectedOrderType = type;
        this.selectedOrder = data;
      }

      return;
    }
    // this.resetForm();
    enableBodyScroll(this.target);
    this.showInfoModal = !this.showInfoModal;
  }

  toggleModal(isOpening, isFirstTime, content?) {
    if (isOpening) {
      if (isFirstTime) {
        this.isFirstTime = isFirstTime;
      }

      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (content != undefined) {
        this.setContentData(content);
      }

      return;
    }
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  editClient(form) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      this.useCases.edit(value);
    }
  }

  cancelRecurrentOrder(order) {
    const dialogRef = this.dialog.open(CancelRecurrentDialog, {
      width: '250px',
      data: { idRecurrent: order.idRecurrent, id: order.id }
    });

    this.cancelModalSub = this.cancelModalSub = dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.useCases.cancelRecurrentOrder(result);
      }
    });
  }

  setContentData(content) {
    this.editForm.patchValue({
      id: content.id,
      name: content.name,
      firstName: content.firstName,
      lastName: content.lastName,
      email: content.email,
      phone: content.phone
    });
  }

  setValidators() {
    this.editForm = this.builder.group({
      id: [""],
      name: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      firstName: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      lastName: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      email: ["", Validators.compose([Validators.email, Validators.required])],
      phone: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])]
    });
  }

  toggleUserDetails() {
    this.showUserDetails = !this.showUserDetails;
  }

  showModalFromUserDetails(user) {
    this.toggleUserDetails();
    this.toggleModal(true, false, user);
  }

  changeUserPassword(user) {
    this.useCases.changePassword(user.email);
    this.toggleUserDetails();
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class PDFViewerComponent implements OnInit {

  @Output() public onClose: EventEmitter<any> = new EventEmitter();

  @Output() public onDownload: EventEmitter<any> = new EventEmitter();

  @Input() show: boolean;

  @Input() downloable: boolean;

  @Input() url: string;

  target = document.querySelector("body");

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.show) {
      disableBodyScroll(this.target);
    }
  }

  hide(): void {
    enableBodyScroll(this.target);
    this.onClose.emit(false);
  }

  download(){
    this.onDownload.emit(false);
  }
}

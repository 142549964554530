import { Category } from './category';

export class Product {
    active: boolean = false;
    category: Category = new Category();
    description: string = "";
    id: string = "";
    imageLinks: string = "";
    name: string = "";
    price: number = 0;
    visible: boolean = false;
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { ShowTotalPipe } from '../pipes/show-total.pipe';
import { Globals } from './globals.service';

@Injectable()
export class CartService {

    cartKey = 'cart';

    private updateCartSubject = new Subject<any>();
    cartObservable = this.updateCartSubject.asObservable();

    snackDuration = 2000;

    restrictions;

    constructor(private snack: MatSnackBar, private showTotal: ShowTotalPipe, private globals: Globals) {
        this.restrictions = this.globals.getDeliveryRestrictions();
    }

    updateCart() {
        this.updateCartSubject.next();
        this.restrictions = this.globals.getDeliveryRestrictions();
    }

    addProduct(product) {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var cartItems = JSON.parse(cart);

            var exists = cartItems.find(item => item.id == product.id);

            if (exists) {
                this.increaseMoreThanOne(product);
                // this.toast.default("Producto agregado al carrito!");
                this.snack.open("Producto agregado al carrito!", null, { duration: this.snackDuration });

                return;
            }

            cartItems.push(this.formatProduct(product));
            this.saveCart(cartItems);

            this.snack.open("Producto agregado al carrito!", null, { duration: this.snackDuration });

            return;
        }
        var aux = [];
        aux.push(this.formatProduct(product));
        this.saveCart(aux);

        this.snack.open("Producto agregado al carrito!", null, { duration: this.snackDuration });
    }

    increaseMoreThanOne(product) {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var cartItems = JSON.parse(cart);
            var item = (cartItems.find(item => item.id == product.id));
            item.quantity += product.quantity;
            this.saveCart(cartItems);

            return;
        }
    }

    increase(product) {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var cartItems = JSON.parse(cart);
            var item = (cartItems.find(item => item.id == product));
            item.quantity++;
            this.saveCart(cartItems);

            return;
        }
    }

    decrease(product) {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var cartItems = JSON.parse(cart);
            var item = (cartItems.find(item => item.id == product));
            if (item.quantity > 1) {
                item.quantity--;
            }
            this.saveCart(cartItems);

            return;
        }
    }

    remove(product) {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var cartItems = JSON.parse(cart);

            if (cartItems.length <= 1) {
                this.clearCart();

                return;
            }

            var index = cartItems.findIndex(item => item.id == product);
            cartItems.splice(index, 1);
            this.saveCart(cartItems);
        }
    }

    getCart() {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var json = JSON.parse(cart);
            return json;
        }
        return null;
    }

    isCartValid() {
        var products = this.getCart();
        if (products != null) {
            var total = this.showTotal.transform(products);
            if (this.restrictions != null) {
                return (total * 100) >= this.restrictions.minimumPurchase;
            }
        }
    }

    getCartSize() {
        var cart = localStorage.getItem(this.cartKey);
        if (cart != null) {
            var json = JSON.parse(cart);
            return json.length;
        }
        return null;
    }

    clearCart() {
        localStorage.removeItem(this.cartKey);

        this.updateCart();
    }

    private saveCart(cartItems) {
        localStorage.setItem(this.cartKey, JSON.stringify(cartItems));

        this.updateCart();
    }

    private formatProduct(product) {
        var item = JSON.parse(JSON.stringify(product));
        delete item.imageLinks;
        delete item.category;
        delete item.description;
        delete item.visible;
        delete item.active;


        return this.updateSubtotal(item);
    }

    private updateSubtotal(product) {
        product.subtotal = (product.quantity * product.price);
        return product;
    }
}
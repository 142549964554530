import { Pipe, PipeTransform } from '@angular/core';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
@Pipe({
    name: 'showImage'
})
export class ShowImagePipe implements PipeTransform {

    constructor(private decoder: Base64Decoder) {

    }

    transform(value: any): any {
        if (value != undefined) {
            return this.decoder.getImageURL(value);
        }
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss']
})
export class HomeInfoComponent implements OnInit {

  @Input() companyData: Observable<any>;

  data;

  isLiteMode = environment.liteMode;

  constructor() { }

  ngOnInit() {
    this.getCompanyData();
  }

  getCompanyData(){
    this.companyData.subscribe((data) => this.data = data[0]);
  }

}

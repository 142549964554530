import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'showRecurrentStatus'
})
export class ShowRecurrentStatusPipe implements PipeTransform {

    constructor() {

    }

    transform(value: any): any {
        switch (value) {
            case 1: {
                return "Activo";
            }
            case 2: {
                return "Cancelado";
            }
        }
    }
}
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { GetService } from 'src/app/core/httpServices/get.service';

@Injectable()
export class FooterUseCases {
    constructor(private getService:GetService, private dataService:DataService) {}
    retrieveCompanyData(){
        this.getService.retrieveData(Endpoints.companyPublic).subscribe(
            (data) => this.setCompanyData(data)
        )
    }

    private setCompanyData(res) {
        this.dataService.updateCompanyData(res.data);
    }

    retrieveDocuments(){
        this.getService.retrieveData(Endpoints.documents).subscribe(
            (data) => this.setDocuments(data)
        )
    }

    private setDocuments(res) {
        this.dataService.updateDocuments(res.data);
    }
}   
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Subscription } from 'rxjs';
import { ClientRegisterUseCases } from './use-cases/client-register-use-cases';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';

@Component({
  selector: 'app-client-register',
  templateUrl: './client-register.component.html',
  styleUrls: ['./client-register.component.scss'],
  providers: [ClientRegisterUseCases]
})
export class ClientRegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;

  hidePassword: boolean = true;
  hideRepeatPassword: boolean = true;

  addSubscription = new Subscription();

  constructor(private auth: LocalAuthService, private useCases: ClientRegisterUseCases, private reloadService: ReloadService, private builder: FormBuilder, private regex: RegexService) { }

  ngOnInit() {
    this.setReloadData();

    this.setValidators();
  }

  ngOnDestroy() {
    this.addSubscription.unsubscribe();
  }

  setReloadData() {
    this.addSubscription = this.reloadService.confirmationAddSuccess.subscribe(
      () => this.onAddSuccess()
    )
  }

  onAddSuccess() {
    var value = this.registerForm.value;
    delete value.name;
    delete value.firstName;
    delete value.lastName;
    delete value.phone;
    delete value.repeatPassword;
    delete value.passwordStrength;
    this.auth.login(value);
    // this.resetForm();
  }

  resetForm() {
    this.registerForm.reset();
  }

  register(form: FormGroup) {
    var isValid = form.valid;
    var value = form.value;

    if (isValid) {
      delete value.repeatPassword;
      delete value.passwordStrength;
      this.useCases.register(value);
    }
  }

  setValidators() {
    this.registerForm = this.builder.group({
      name: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      firstName: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      lastName: ["", Validators.compose([Validators.pattern(this.regex.nameRegex)])],
      email: ["", Validators.compose([Validators.email, Validators.required])],
      phone: ["", Validators.compose([Validators.pattern(this.regex.nameRegex), Validators.required])],
      password: ["", Validators.compose([Validators.pattern(this.regex.passwordRegex), Validators.required])],
      repeatPassword: ["", Validators.compose([Validators.pattern(this.regex.passwordRegex), Validators.required])],
      passwordStrength: [0, Validators.compose([Validators.min(1), Validators.required])],
    }, { validator: this.passwordConfirming });
  }

  passwordConfirming(c: AbstractControl) {
    if (c.get('password').value !== c.get('repeatPassword').value) {
      return c.get('repeatPassword').setErrors({ missmatch: true });
    }
  }

  onStrengthChange(event) {
    this.registerForm.get("passwordStrength").setValue(event);
  }

}

import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';
import { Role } from 'src/app/core/models/role';
import { CartService } from 'src/app/core/tools/cart.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent implements OnInit, OnDestroy {
  isShowLogin = false;
  isShowCart = false;
  isShowSidenav = false;

  nav;

  currentUser;
  role;

  cartSize = 0;

  facebookUrl = "https://www.facebook.com/paneramx/";
  twitterUrl = "https://twitter.com/lapanera";
  instagramUrl = "https://www.instagram.com/paneramx/";

  cartSub = new Subscription();


  constructor(private auth: LocalAuthService, private cart: CartService) {
    this.currentUser = this.auth.currentUserValue;
    this.role = Role;
  }

  ngOnInit() {
    this.reloadCart();

    this.getCartSize();

    this.nav = document.querySelector('#navbar-custom');
  }

  ngOnDestroy() {
    this.cartSub.unsubscribe();
  }

  reloadCart() {
    this.cartSub = this.cart.cartObservable.subscribe(
      () => this.getCartSize()
    );
  }

  getCartSize() {
    this.cartSize = this.cart.getCartSize();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {

    if (window.pageYOffset > this.nav.clientHeight) {
      this.nav.classList.add('scrolled');
      // nav.classList.remove('top');
      // fabNetworks.classList.add('scrolled');

      // this.navLogoUrl = this.logoUrl;

    } else {
      this.nav.classList.remove('scrolled');
      // nav.classList.add('top');
      // fabNetworks.classList.remove('scrolled');

      // this.navLogoUrl = this.whiteLogoUrl;
    }
  }

  toggleLogin() {
    this.isShowLogin = !this.isShowLogin;
  }

  logout() {
    this.auth.logout(false);
  }

  toggleCart(event) {
    if (event != undefined) {
      if (event) {
        this.isShowCart = !this.isShowCart;
        this.isShowLogin = true;

        return;
      }

      this.isShowCart = !this.isShowCart;
      return;
    }
    this.isShowCart = !this.isShowCart;
  }

  toggleSidenav() {
    this.isShowSidenav = !this.isShowSidenav;
  }

  goToFacebook() {
    window.open(this.facebookUrl, "_blank");
  }

  goToTwitter() {
    window.open(this.twitterUrl, "_blank");
  }

  goToInstagram() {
    window.open(this.instagramUrl, "_blank");
  }
}

import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';

@Injectable()
export class ChangePasswordUseCases {
    constructor(private postService: PostService) { }

    changePassword(user) {
        this.postService.addData(null, `${Endpoints.updatePassword}?email=${user.email}&password=${user.password}&code=${user.code}`);
    }
}   
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable()
export class ReloadService {
    private confirmationGet = new Subject<any>();
    confirmationGetSuccess = this.confirmationGet.asObservable();

    private confirmationAdd = new Subject<any>();
    confirmationAddSuccess = this.confirmationAdd.asObservable();

    private confirmationEdit = new Subject<any>();
    confirmationEditSuccess = this.confirmationEdit.asObservable();

    private confirmationEditWithoutBody = new Subject<any>();
    confirmationEditWithoutBodySuccess = this.confirmationEditWithoutBody.asObservable();

    private confirmationEditReturningObject = new Subject<any>();
    confirmationEditReturningObjectSuccess = this.confirmationEditReturningObject.asObservable();

    private confirmationDeleteUsingBody = new Subject<any>();
    confirmationDeleteUsingBodySuccess = this.confirmationDeleteUsingBody.asObservable();

    private confirmationDelete = new Subject<any>();
    confirmationDeleteSuccess = this.confirmationDelete.asObservable();

    private confirmationDisable = new Subject<any>();
    confirmationDisableSuccess = this.confirmationDisable.asObservable();

    constructor() { }

    confirmGet() {
        this.confirmationGet.next();
    }

    confirmAdd() {
        this.confirmationAdd.next();
    }

    confirmEdit() {
        this.confirmationEdit.next();
    }

    confirmEditWithoutBody() {
        this.confirmationEditWithoutBody.next();
    }

    confirmDeleteUsingBody() {
        this.confirmationDeleteUsingBody.next();
    }

    confirmDelete() {
        this.confirmationDelete.next();
    }

    confirmDisable() {
        this.confirmationDisable.next();
    }

    confirmEditReturningObject(data) {
        this.confirmationEditReturningObject.next(data);
    }
}
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalAuthService } from '../authServices/auth.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toast: ToastrService, private authenticationService: LocalAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!this.isOnline()) {
            this.toast.error("Revise su conexión a internet e intente nuevamente");

            return of(undefined);
        }

        // this.isTokenExpired();

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((err: HttpErrorResponse) => {
                var path = err.url.split("/")[5];
                if ([401, 403].indexOf(err.status) !== -1) {
                    if (path != "authentication") {
                        // this.authenticationService.logout();
                    }
                }

                const message = err.error.data;
                switch (err.error.status) {
                    case "error": {
                        this.toast.error(message);

                        break;
                    }
                    case "warn": {
                        this.toast.warning(message);

                        break;
                    }
                    case "info": {
                        this.toast.info(message);

                        break;
                    }
                }
                return throwError(err);
            }))
    }

    isTokenExpired() {
        if (this.authenticationService.currentUserValue != null) {
            var token = this.authenticationService.currentUserValue.token;
        }
    }

    isOnline() {
        return window.navigator.onLine;
    }
}
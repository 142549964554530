import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';

@Injectable()
export class LoginUseCases {
    constructor(
        private postService: PostService
    ) { }

    changePassword(email) {
        this.postService.addData(null, `${Endpoints.requestChangePassword}?email=${email}`);
    }
}   
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersAdminUseCases } from './use-cases/users-admin-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { trigger, style, transition, animate, state } from '@angular/animations';

@Component({
  selector: 'app-users-administration',
  templateUrl: './users-administration.component.html',
  styleUrls: ['./users-administration.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
  providers: [UsersAdminUseCases]
})
export class UsersAdministrationComponent implements OnInit, OnDestroy {

  searchingUsers = false;

  usersList: Observable<any>;

  editForm: FormGroup;

  showModal = false;

  target = document.querySelector("body");

  searchFilter = "";

  getSub = new Subscription();
  editSub = new Subscription();

  constructor(private regex: RegexService, private builder: FormBuilder, private reloadService: ReloadService, private useCases: UsersAdminUseCases, private dataService: DataService) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.reloadData();
    this.setValidators();

    this.useCases.retrieveUsers();
    this.searchingUsers = true;
    this.getUsers();
  }

  ngOnDestroy() {
    this.getSub.unsubscribe();
    this.editSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );

    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );
  }

  onGetSuccess() {
    this.searchingUsers = false;
  }

  onEditSuccess() {
    this.toggleModal(false);
    this.resetForm();
    this.useCases.retrieveUsers();
  }

  getUsers() {
    this.usersList = this.dataService.currentUsersList;
  }

  toggleModal(isOpening, content?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (content != undefined) {
        this.setEditData(content);
      }

      return;
    }
    this.resetForm();
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  setEditData(content) {
    this.editForm.patchValue({
      id: content.id,
      name: content.name,
      firstName: content.firstName,
      lastName: content.lastName,
      phone: content.phone
    });
  }

  editUser(form) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      this.useCases.edit(value);
    }
  }

  resetForm() {
    this.editForm.reset({
      id: ""
    });
  }

  setValidators() {
    this.editForm = this.builder.group({
      id: [""],
      name: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      firstName: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      lastName: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      phone: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.phonePattern)])]
    });
  }
}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class CategoriesUseCases {
    constructor(private reloadService: ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    add(category) {
        this.postService.addData(category, Endpoints.categoriesPrivate);
    }

    edit(category) {
        this.postService.editData(category, Endpoints.categoriesPrivate);
    }

    delete(category) {
        this.postService.deleteData(category, Endpoints.categoriesPrivate);
    }

    retrieve() {
        var result = this.getService.retrieveData(Endpoints.categoriesPublic);
        result.subscribe(
            (result) => this.setContentData(result)
        );
    }
    private setContentData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateCategoriesList(res.data);
    }
}   
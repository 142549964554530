import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../tools/globals.service';
import { ReloadService } from '../dataServices/reload.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class PostService {
    url: string;
    path = "";

    constructor(public http: HttpClient, private toast: ToastrService, private reloadService: ReloadService, private globals: Globals) {
        this.getURL();
    }

    getURL() {
        this.url = this.globals.getURL();
    }

    addData(data, endpoint) {
        let json = JSON.stringify(data);
        let headers = this.getJsonHeaders();

        this.http.post(this.url + endpoint, json, { headers: headers })
            .subscribe(
                (data) => this.onSuccess(data),
                (error) => this.handleError(error))
    }

    // addDataHavingResponse(data, endpoint) {
    //     let json = JSON.stringify(data);
    //     let headers = this.getJsonHeaders();

    //     this.http.post(this.url + endpoint, json, { headers: headers })
    //         .subscribe(
    //             (data) => this.onSuccessContainingData(data),
    //             (error) => this.handleError(error))
    // }

    editData(data, endpoint) {
        let json = JSON.stringify(data);
        let headers = this.getJsonHeaders();

        this.http.put(this.url + endpoint, json, { headers: headers })
            .subscribe(
                (data) => this.onSuccessEdit(data),
                (failure) => this.handleError(failure))
    }

    editDataWithoutBody(endpoint) {
        let headers = this.getJsonHeaders();

        this.http.put(this.url + endpoint, null, { headers: headers })
            .subscribe(
                (data) => this.onSuccessEditWithoutBody(data),
                (failure) => this.handleError(failure))
    }

    editDataReturningObject(dataToEdit, endpoint) {
        let json = JSON.stringify(dataToEdit);
        let headers = this.getJsonHeaders();

        this.http.put(this.url + endpoint, json, { headers: headers })
            .subscribe(
                (data) => this.onSuccessEditReturningObject(data, dataToEdit),
                (failure) => this.handleError(failure))
    }

    deleteUsingBody(data, endpoint) {
        let json = JSON.stringify(data);
        let headers = this.getJsonHeaders();

        this.http.put(this.url + endpoint, json, { headers: headers })
            .subscribe(
                (data) => this.onSuccessDeleteUsingBody(data),
                (failure) => this.handleError(failure))
    }

    deleteData(data, endpoint) {
        let headers = this.getJsonHeaders();

        this.http.delete(this.url + endpoint + data + "/", { headers: headers })
            .subscribe(
                (data) => this.onSuccessDelete(data),
                (failure) => this.handleError(failure))
    }

    disableData(data, endpoint) {
        let json = JSON.stringify(data);
        let headers = this.getJsonHeaders();

        this.http.put(this.url + endpoint, json, { headers: headers })
            .subscribe(
                (data) => this.onSuccessDelete(data),
                (failure) => this.handleError(failure))
    }

    onSuccess(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmAdd();
    }

    onSuccessEditReturningObject(success, data) {
        this.showSuccess(success.data);
        this.reloadService.confirmEditReturningObject(data);
    }

    onSuccessEdit(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmEdit();
    }

    onSuccessEditWithoutBody(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmEditWithoutBody();
    }

    onSuccessDeleteUsingBody(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmDeleteUsingBody();
    }

    onSuccessDelete(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmDelete();
    }

    onSuccessDisable(success) {
        this.showSuccess(success.data);
        this.reloadService.confirmDisable();
    }

    handleError(error, type?) {
        throw new Error(error.error.message);
    }

    showSuccess(message) {
        this.toast.success(message);
    }

    getJsonHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
}
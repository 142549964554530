import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalAuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: LocalAuthService,
        private toast: ToastrService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.auth.currentUserValue;
        const token = (currentUser != null ? currentUser.token : "");
        if (currentUser) {
            if (this.auth.isTokenExpired(token)) {
                this.toast.warning("Su sesión expiró, debe ingresar nuevamente");

                setTimeout(() => {
                    this.auth.logout(true);
                }, 3000);

                return false;
            }
            if (route.data.roles && route.data.roles.indexOf(currentUser.rol) === -1) {
                this.router.navigate(['/404']);
                return false;
            }
            return true;
        }
        this.auth.logout(false);
        return false;
    }
}
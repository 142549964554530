import { Pipe, PipeTransform } from '@angular/core';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
import { Globals } from '../tools/globals.service';
@Pipe({
    name: 'showFullTotal'
})
export class ShowFullTotalPipe implements PipeTransform {

    constructor(private globals: Globals) { }

    transform(value: any): any {
        if (value != null) {
            var sum = value.reduce((sum, item) => sum += ((item.price * item.quantity)), 0);
            var restrictions = this.getDeliveryRestrictions();
            return (sum + restrictions.deliveryPrice)/100;
        }
        return 0;
    }

    getDeliveryRestrictions() {
        return this.globals.getDeliveryRestrictions();
    }
}
import { Component, OnInit } from '@angular/core';
import { AboutUseCases } from './use-cases/about-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [AboutUseCases]
})
export class AboutComponent implements OnInit {

  constructor(private dataService:DataService, private useCases:AboutUseCases) { }

  companyData: Observable<any>;

  ngOnInit() {
    this.useCases.retrieve();
    this.getCompanyData();
  }

  getCompanyData(){
    this.companyData = this.dataService.currentCompanyData;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-recommendations',
  templateUrl: './home-recommendations.component.html',
  styleUrls: ['./home-recommendations.component.scss']
})
export class HomeRecommendationsComponent implements OnInit {

  @Input() categoriesList: Observable<any>;

  categoriesArray = new Array();

  constructor(private route: Router) { }

  ngOnInit() {
    this.categoriesList.subscribe(
      (data) => this.categoriesArray = data
    )
  }

  goToProducts(category) {
    this.route.navigate(['productos', { category: category.id, name:category.name }]);
  }
}

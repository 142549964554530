import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    idRecurrentOrder: string;
    id: string;
}

@Component({
    selector: 'cancel-recurrent-dialog',
    templateUrl: 'cancel-recurrent-dialog.html',
})
export class CancelRecurrentDialog {

    constructor(
        public dialogRef: MatDialogRef<CancelRecurrentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { GetService } from 'src/app/core/httpServices/get.service';

@Injectable()
export class AboutUseCases {
    constructor(private getService:GetService, private dataService:DataService) {}

    retrieve(){
        this.getService.retrieveData(Endpoints.companyPublic).subscribe(
            (data) => this.setData(data)
        )
    }

    private setData(res) {
        this.dataService.updateCompanyData(res.data);
    }
}   
import { Injectable } from '@angular/core';

import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from 'file-saver';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_FILE_NAME = 'produccion';

@Injectable()
export class ExcelGeneratorService {

    title = "Listado de pedidos para producción";

    header = ["Fecha Entrega", "ID Pedido", "Nombre Producto", "Cantidad", "Precio Unitario", "Dirección Entrega", "Nombre", "Fecha Creación", "Costo Envío", "Total"];

    workbook;

    constructor(private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private toast: ToastrService) {
    }

    private exportAsExcel(data, inputData) {
        this.workbook = new Excel.Workbook();
        let worksheet = this.workbook.addWorksheet("Data");


        // Add new row
        let titleRow = worksheet.addRow([this.title]);
        // Set font, size and style in title row.
        titleRow.font = { name: 'Arial Black', family: 2, size: 16, bold: true };

        // Blank Row
        worksheet.addRow([]);
        //Add row with current date
        let subTitleRow = worksheet.addRow(['Fecha de creación de reporte : ' + this.datePipe.transform(new Date(), 'dd/MMM/yyyy')]);
        worksheet.addRow([`Rango de fechas: ${this.datePipe.transform(inputData.startDate, 'dd/MMM/yyyy')} a ${this.datePipe.transform(inputData.endDate, 'dd/MMM/yyyy')}`]);
        worksheet.addRow([`Estatus de listado: ${inputData.status.name}`]);


        worksheet.addRow([]);
        let subTitleRow2 = worksheet.addRow(["Listado de Productos"])
        subTitleRow2.font = { name: 'Arial Black', family: 2, size: 12, bold: true };

        //Blank Row 
        worksheet.addRow([]);
        let headerRow = worksheet.addRow(this.header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCA00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        });

        data.forEach(value => {


            value.orderProducts.forEach(item => {

                var items = [];
                items[0] = this.datePipe.transform(new Date(value.deliveryDate), 'dd/MMM/yyyy');
                items[1] = value.idOrder;
                items[2] = item.name;
                items[3] = item.quantity;
                items[4] = this.currencyPipe.transform(item.price / 100, 'MXN', 'symbol-narrow', '1.2-2');
                items[5] = value.address;

                if (value.user == null) {
                    items[6] = value.name;
                } else {
                    items[6] = value.user.name;
                }

                items[7] = this.datePipe.transform(new Date(value.timestamp), 'dd/MMM/yyyy');
                items[8] = this.currencyPipe.transform(value.deliveryCost / 100, 'MXN', 'symbol-narrow', '1.2-2');
                items[9] = this.currencyPipe.transform(value.total / 100, 'MXN', 'symbol-narrow', '1.2-2');

                let row = worksheet.addRow(items);
            }
            );
        }
        )

    }

    saveExcelFile(data, inputData) {
        if (data.length > 0) {
            this.exportAsExcel(data, inputData);

            this.workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], { type: EXCEL_TYPE });
                FileSaver.saveAs(blob, `${EXCEL_FILE_NAME}${EXCEL_EXTENSION}`);
            });
            return;
        }
        this.toast.info("No se encontraron resultados");
    }
}
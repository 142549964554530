import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeUseCases } from './use-cases/home-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Observable, Subscription } from 'rxjs';
import { Globals } from 'src/app/core/tools/globals.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeUseCases]
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private globals:Globals, private useCases: HomeUseCases, private dataService: DataService) { }

  categoriesList: Observable<any>;
  imagesList: Observable<any>;
  companyData: Observable<any>;

  deliverySub = new Subscription();

  isLiteMode = environment.liteMode;

  ngOnInit() {

    this.useCases.retrieveCategoriesData();
    this.getCategoriesData();

    this.useCases.retrieveCarouselData();
    this.getCarouselImages();

    this.useCases.retrieveCompanyData();
    this.getCompanyData();

    this.useCases.retrieveDeliveryRestrictions();
    this.getDeliveryRestrictions();
  }

  ngOnDestroy(){
    this.deliverySub.unsubscribe();
  }

  getCategoriesData() {
    this.categoriesList = this.dataService.currentCategoriesList;
  }

  getCarouselImages() {
    this.imagesList = this.dataService.currentCarouselImages;
  }

  getCompanyData() {
    this.companyData = this.dataService.currentCompanyData;
  }

  getDeliveryRestrictions() {
    this.deliverySub = this.dataService.currentDeliveryRestrictions.subscribe((data) => this.saveDeliveryRestrictions(data[0]));
  }

  saveDeliveryRestrictions(data){
    this.globals.setDeliveryRestrictions(data);
  }
}

import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { GetService } from 'src/app/core/httpServices/get.service';

@Injectable()
export class HomeUseCases {
    constructor(private getService:GetService, private dataService:DataService) {}
    retrieveCompanyData(){
        this.getService.retrieveData(Endpoints.companyPublic).subscribe(
            (data) => this.setCompanyData(data)
        )
    }

    private setCompanyData(res) {
        this.dataService.updateCompanyData(res.data);
    }

    retrieveCategoriesData(){
        this.getService.retrieveData(Endpoints.categoriesPublic).subscribe(
            (data) => this.setCategoriesData(data)
        )
    }

    private setCategoriesData(res) {
        this.dataService.updateCategoriesList(res.data);
    }

    retrieveCarouselData(){
        this.getService.retrieveData(Endpoints.carouselPublic).subscribe(
            (data) => this.setCarouselData(data)
        )
    }

    private setCarouselData(res) {
        this.dataService.updateCarouselImages(res.data);
    }

    retrieveDeliveryRestrictions(){
        this.getService.retrieveData(Endpoints.deliveryRestrictions).subscribe(
            (data) => this.setDeliveryRestrictionsData(data)
        )
    }

    private setDeliveryRestrictionsData(res) {
        this.dataService.updateDeliveryRestrictions(res.data);
    }
}   
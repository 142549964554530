import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { User } from '../models/user';
import { Endpoints } from '../enums/endpoints';
import { Globals } from '../tools/globals.service';
import { Router } from '@angular/router';
import { Role } from '../models/role';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AESService } from '../tools/aes';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Injectable({ providedIn: 'root' })
export class LocalAuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private localStorageKey = "user";

    private userUrl = "mi-perfil";

    private adminUrl = "administracion-pedidos";

    private homeUrl = "/home";

    private socialUser: SocialUser;

    target = document.querySelector("body");

    constructor(
        private aes: AESService,
        private jwtHelper: JwtHelperService,
        private router: Router, private globals:
            Globals,
        private http: HttpClient,
        private socialAuthService: AuthService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(aes.decrypt(localStorage.getItem(this.localStorageKey))));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data) {
        var headers = this.getHeaders();
        return this.http.post<any>(`${this.globals.getURL()}${Endpoints.login}`, data, { headers })
            .pipe(
                map(res => {
                    var user = res.data;
                    localStorage.setItem(this.localStorageKey, this.aes.encrypt(JSON.stringify(user)));
                    this.currentUserSubject.next(user);
                    return user;
                }))
            // .pipe(first())
            .subscribe(
                res => {
                    enableBodyScroll(this.target);
                    if (res.rol == Role.Admin) {
                        this.router.navigate([this.adminUrl]);

                        return;
                    }
                    this.router.navigate([this.userUrl]);
                },
            );
    }

    loginWithGoogle() {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
            data => {
                this.socialUser = data;
                const tokenGoogle = { value: this.socialUser.idToken };
                this.google(tokenGoogle);
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    private google(token) {
        var headers = this.getHeaders();
        return this.http.post<any>(`${this.globals.getURL()}${Endpoints.googleLogin}`, token, { headers })
            .pipe(
                map(res => {
                    var user = res.data;
                    localStorage.setItem(this.localStorageKey, this.aes.encrypt(JSON.stringify(user)));
                    this.currentUserSubject.next(user);
                    return user;
                }))
            .subscribe(
                res => {
                    enableBodyScroll(this.target);
                    if (res.rol == Role.Admin) {
                        this.router.navigate([this.adminUrl]);

                        return;
                    }
                    this.router.navigate([this.userUrl]);
                },
            );
    }

    loginWithFacebook() {
        console.log(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
            data => {
                this.socialUser = data; console.log(this.socialUser)
                const tokenFacebook = { value: this.socialUser.authToken };
                this.facebook(tokenFacebook);
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    private facebook(token) {
        var headers = this.getHeaders();
        return this.http.post<any>(`${this.globals.getURL()}${Endpoints.facebookLogin}`, token, { headers })
            .pipe(
                map(res => {
                    var user = res.data;
                    localStorage.setItem(this.localStorageKey, this.aes.encrypt(JSON.stringify(user)));
                    this.currentUserSubject.next(user);
                    return user;
                }))
            .subscribe(
                res => {
                    enableBodyScroll(this.target);
                    if (res.rol == Role.Admin) {
                        this.router.navigate([this.adminUrl]);

                        return;
                    }
                    this.router.navigate([this.userUrl]);
                },
            );
    }

    logout(isHome) {
        let user = this.currentUserValue;
        if (user) {
            var email = user.email;
            if (user.isSocialLogin) {
                this.socialAuthService.signOut().then(
                    (data) => {
                        this.doLogout(email, isHome);
                    }
                );
                return;
            }
            this.doLogout(email, isHome);
        }
    }

    doLogout(email, isHome) {
        var headers = this.getHeaders();
        this.http.post<any>(`${this.globals.getURL()}${Endpoints.logout}${email}/`, {}, { headers })
            .pipe(first())
            .subscribe(
                res => {
                    localStorage.removeItem(this.localStorageKey);
                    this.currentUserSubject.next(null);
                    if (isHome) {
                        this.router.navigate([this.homeUrl]);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);

                        return;
                    }
                    this.router.navigate([this.homeUrl]);
                },
            );
    }



    getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    isTokenExpired(token) {
        if (token != null || token != "" || token != undefined) {
            return this.jwtHelper.isTokenExpired(token);
        }
        return false;
    }
}
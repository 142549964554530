import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { RegexService } from 'src/app/core/tools/regex.service';
import { ChangePasswordUseCases } from './use-cases/change-password-use-cases';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ChangePasswordUseCases]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  passwordForm: FormGroup;

  private confirmationSub = new Subscription();

  hidePassword: boolean = true;
  hideRepeatPassword: boolean = true;

  email;

  constructor(private builder: FormBuilder,
    private regex: RegexService,
    private useCases: ChangePasswordUseCases,
    private jwtHelper: JwtHelperService,
    private route: ActivatedRoute,
    private reloadService: ReloadService,
    private router: Router) { }

  ngOnInit() {
    this.setValidators();
    this.reloadData();

    this.validateToken(this.route.snapshot.params.token);
  }

  ngOnDestroy() {
    this.confirmationSub.unsubscribe();
  }

  reloadData() {
    this.confirmationSub = this.reloadService.confirmationAddSuccess.subscribe(
      () => {
        this.goToHomePage();
      }
    )
  }

  goToHomePage() {
    this.router.navigate(['/home']);
  }

  validateToken(token) {
    var date = this.jwtHelper.getTokenExpirationDate(token); console.log(date)
    var isExpired = this.jwtHelper.isTokenExpired(token);

    if (isExpired) {
      // return;
    }

    var payload = this.jwtHelper.decodeToken(token);

    this.email = payload.email;
  }

  onResetPassword(form: FormGroup) {
    var valid = form.valid;
    var value = form.value;
    if (valid) {
      value.email = this.email;console.log(value)

      delete value.repeatPassword;
      delete value.passwordStrength;

      this.useCases.changePassword(value);
    }
  }

  setValidators() {
    this.passwordForm = this.builder.group({
      email: [""],
      password: ["", Validators.compose([Validators.pattern(this.regex.passwordRegex), Validators.required])],
      repeatPassword: ["", Validators.compose([Validators.pattern(this.regex.passwordRegex), Validators.required])],
      passwordStrength: [0, Validators.compose([Validators.min(1), Validators.required])],
      code: ["", Validators.compose([Validators.required])]
    }, { validator: this.passwordConfirming });
  }

  passwordConfirming(c: AbstractControl) {
    if (c.get('password').value !== c.get('repeatPassword').value) {
      return c.get('repeatPassword').setErrors({ missmatch: true });
    }
  }

  onStrengthChange(event) {
    this.passwordForm.get("passwordStrength").setValue(event);
  }

}

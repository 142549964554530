import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { OrdersAdminUseCases } from './use-cases/orders-admin-use-cases';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Observable, Subscription } from 'rxjs';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ExcelGeneratorService } from 'src/app/core/tools/excel-generator.service';
import { RegexService } from 'src/app/core/tools/regex.service';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material';
import { ConfirmCancelDialog } from './dialog/confirm-cancel-dialog';

@Component({
  selector: 'app-orders-administration',
  templateUrl: './orders-administration.component.html',
  styleUrls: ['./orders-administration.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
  providers: [OrdersAdminUseCases]
})
export class OrdersAdministrationComponent implements OnInit, OnDestroy {

  ordersList: Observable<any>;

  pdfOrdersList = [];

  excelOrdersList = [];

  orderStatus = [];

  showModal: boolean = false;

  showInfoModal: boolean = false;

  showExcelModal: boolean = false;

  showPDFModal: boolean = false;

  target = document.querySelector("body");

  searchingOrders: boolean = false;

  searchFilter = "";

  filterDate = Date.now();

  filterForm: FormGroup;

  excelForm: FormGroup;

  pdfForm: FormGroup;

  editForm: FormGroup;

  selectedOrder;

  cancelledStatus = 6;

  getSub = new Subscription();
  addSub = new Subscription();
  editSub = new Subscription();
  updateStatusSub = new Subscription();
  excelSub = new Subscription();
  pdfSub = new Subscription();
  statusSub = new Subscription();
  dialogSub = new Subscription();

  constructor(private dialog: MatDialog, private base64Decoder: Base64Decoder, private regex: RegexService, private excelGenerator: ExcelGeneratorService, private builder: FormBuilder, private dataService: DataService, private useCases: OrdersAdminUseCases, private reloadService: ReloadService) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.reloadData();

    this.setValidators();

    this.searchingOrders = true;
    this.filterForm.get("startDate").setValue(new Date(this.filterDate));
    this.filterForm.get("endDate").setValue(new Date(this.filterDate));
    this.useCases.retrieveOrders(this.formatBeginDate(this.formatBeginDate(this.filterForm.value.startDate)), this.formatEndDate(this.filterForm.value.startDate));
    this.getOrders();

    this.useCases.retrieveOrderStatus();
    this.getOrderStatus();

    this.getExcelOrders();
    this.getPdfOrders();
  }

  ngOnDestroy() {
    this.getSub.unsubscribe();
    this.addSub.unsubscribe();
    this.editSub.unsubscribe();
    this.updateStatusSub.unsubscribe();
    this.excelSub.unsubscribe();
    this.pdfSub.unsubscribe();
    this.statusSub.unsubscribe();
    this.dialogSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );

    this.addSub = this.reloadService.confirmationAddSuccess.subscribe(
      () => this.onCancelSuccess()
    );

    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );

    this.updateStatusSub = this.reloadService.confirmationEditWithoutBodySuccess.subscribe(
      () => this.onUpdateStatusSuccess()
    );
  }

  onGetSuccess() {
    this.searchingOrders = false;
  }

  onEditSuccess() {
    this.toggleModal(false);
    this.searchingOrders = true;
    this.clearForm();
    this.useCases.retrieveOrders(this.formatBeginDate(this.filterForm.value.startDate), this.formatEndDate(this.filterForm.value.endDate));
  }

  onUpdateStatusSuccess() {
    this.searchingOrders = true;
    this.useCases.retrieveOrders(this.formatBeginDate(this.filterForm.value.startDate), this.formatEndDate(this.filterForm.value.endDate));
  }

  onCancelSuccess() {
    this.searchingOrders = true;
    this.useCases.retrieveOrders(this.formatBeginDate(this.filterForm.value.startDate), this.formatEndDate(this.filterForm.value.endDate));
  }

  getOrders() {
    this.ordersList = this.dataService.currentOrders;
  }

  getExcelOrders() {
    this.excelSub = this.dataService.currentExcelOrders.subscribe((data) => {
      this.excelGenerator.saveExcelFile(data, this.excelForm.value);
    });
  }

  getPdfOrders() {
    this.pdfSub = this.dataService.currentPdfOrders.subscribe((data) => {
      var PDFBlob = this.base64Decoder.getPDFBlob(data);
      FileSaver.saveAs(PDFBlob, "entregas.pdf");
    });
  }

  getOrderStatus() {
    this.statusSub = this.dataService.currentOrderStatus.subscribe((data) => {
      this.orderStatus = data;
    });
  }

  filterOrders(form) {
    var value = form.getRawValue();
    this.searchingOrders = true;
    this.useCases.retrieveOrders(this.formatBeginDate(value.startDate.getTime()), this.formatEndDate(value.endDate.getTime()));
  }

  generateOrdersExcel(form) {
    var value = form.getRawValue();
    var valid = form.valid;
    if (valid) {
      this.useCases.retrieveExcelOrders(this.formatBeginDate(value.startDate.getTime()), this.formatEndDate(value.endDate.getTime()), value.status);
    }
  }

  generateOrdersPdf(form) {
    var value = form.getRawValue();
    var valid = form.valid;
    if (valid) {
      this.useCases.retrievePdfOrders(this.formatBeginDate(value.startDate.getTime()), this.formatEndDate(value.endDate.getTime()), value.status);
    }
  }

  togglePdfModal(isOpening) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showPDFModal = !this.showPDFModal;

      return;
    }
    // this.resetForm();
    enableBodyScroll(this.target);
    this.showPDFModal = !this.showPDFModal;
  }

  toggleExcelModal(isOpening) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showExcelModal = !this.showExcelModal;

      return;
    }
    // this.resetForm();
    enableBodyScroll(this.target);
    this.showExcelModal = !this.showExcelModal;
  }

  toggleInfoModal(isOpening, data?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showInfoModal = !this.showInfoModal;

      if (data != undefined) {
        this.selectedOrder = data;
      }

      return;
    }
    // this.resetForm();
    enableBodyScroll(this.target);
    this.showInfoModal = !this.showInfoModal;
  }

  toggleModal(isOpening, data?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (data != undefined) {
        this.setEditData(data);
      }

      return;
    }
    // this.resetForm();
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  setEditData(data) {console.log(data)
    this.editForm.patchValue({
      address: data.address,
      deliveryDate: new Date(data.deliveryDate),
      id: data.id,
      orderProducts: data.orderProducts,
      payment: data.payment,
      status: data.status,
      timestamp: data.timestamp,
      total: data.total,
      user: data.user,
      idOrder: data.idOrder
    });
  }

  editOrder(form) {
    var valid = form.valid;
    var value = form.getRawValue();

    if (valid) {
      this.useCases.edit(this.formatDate(value));
    }
  }

  onStatusChange(status, order) {
    var value = status.value;
    this.useCases.updateStatus(order.id, value.status);
  }

  onCancelOrder(order) {
    const dialogRef = this.dialog.open(ConfirmCancelDialog, {
      width: '250px',
      data: { order: order }
    });

    this.dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        var orderResult = result.order;
        this.useCases.cancel(orderResult);
        return;
      }
    });
  }

  setValidators() {
    this.filterForm = this.builder.group({
      startDate: [new Date(this.formatBeginDate(this.filterDate)), Validators.required],
      endDate: [new Date(this.formatEndDate(this.filterDate)), Validators.required],
    });

    this.excelForm = this.builder.group({
      startDate: [new Date(this.formatBeginDate(this.filterDate)), Validators.required],
      status: ['', Validators.required],
      endDate: [new Date(this.formatEndDate(this.filterDate)), Validators.required],
    });

    this.pdfForm = this.builder.group({
      startDate: [new Date(this.formatBeginDate(this.filterDate)), Validators.required],
      status: ['', Validators.required],
      endDate: [new Date(this.formatEndDate(this.filterDate)), Validators.required],
    });

    this.editForm = this.builder.group({
      address: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.addressRegex)])],
      deliveryDate: ["", Validators.required],
      id: [""],
      orderProducts: [""],
      payment: [""],
      status: [""],
      timestamp: [""],
      total: [""],
      user: [""],
      idOrder: [""]
    });
  }

  formatDate(value) {
    value.deliveryDate = value.deliveryDate.getTime();
    return value;
  }

  clearForm() {
    this.editForm.reset();
  }

  formatBeginDate(timestamp) {
    var date = new Date(timestamp);
    date.setHours(0, 0, 0);
    return date.getTime();
  }

  formatEndDate(timestamp) {
    var date = new Date(timestamp);
    date.setHours(23, 59, 59);
    return date.getTime();
  }

  statusCompareWith(item1, item2) {
    return item1 && item2 ? (item1.status === item2) : item1 === item2;
  }
}

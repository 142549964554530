import { Injectable } from '@angular/core';

// import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from 'file-saver';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_FILE_NAME = 'produccion';

@Injectable()
export class ExcelReportService {

    title = "Reporte de Usuarios";

    header = ["Email", "Name", "Apellido Materno", "Apellido Paterno", "Télefono", "Número de Compras Completadas", "Cantidad Total Comprada"];

    workbook;

    constructor(private datePipe: DatePipe, 
        private currencyPipe: CurrencyPipe,
        private toast:ToastrService) {
    }

    private exportAsExcel(data) {
        this.workbook = new Excel.Workbook();
        let worksheet = this.workbook.addWorksheet("Data");


        // Add new row
        let titleRow = worksheet.addRow([this.title]);
        // Set font, size and style in title row.
        titleRow.font = { name: 'Arial Black', family: 2, size: 14, bold: true };

        // Blank Row
        worksheet.addRow([]);
        //Add row with current date
        let subTitleRow = worksheet.addRow(['Fecha del reporte : ' + this.datePipe.transform(new Date(), 'dd/MMM/yyyy')]);


        worksheet.addRow([]);
        let subTitleRow2 = worksheet.addRow(["Listado de Produtos"])
        subTitleRow2.font = { name: 'Arial Black', family: 2, size: 12, bold: true };

        //Blank Row 
        worksheet.addRow([]);
        let headerRow = worksheet.addRow(this.header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCA00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        });

        data.forEach(value => {

            var items = [];
            items[0] = value.email;
            items[1] = value.name;
            items[2] = value.firstName;
            items[3] = value.lastName;
            items[4] = value.phone;
            items[5] = value.numberOrders;
            items[6] = this.currencyPipe.transform(value.purchasedAmount/100, 'MXN', 'symbol-narrow', '1.2-2');
            let row = worksheet.addRow(items);

        }
        )

    }

    saveExcelFile(data) {
        if (data.length > 0) {
            this.exportAsExcel(data);

            this.workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], { type: EXCEL_TYPE });
                FileSaver.saveAs(blob, `${EXCEL_FILE_NAME}${EXCEL_EXTENSION}`);
            });
            return;
        }
        this.toast.info("No se encontraron resultados");
    }
}
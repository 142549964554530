import { Pipe, PipeTransform } from '@angular/core';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
@Pipe({
    name: 'showImages'
})
export class ShowImagesPipe implements PipeTransform {

    constructor(private decoder: Base64Decoder) {

    }

    transform(value: any): any {
        var aux:any = Array.from(value);
        aux.forEach(element => {
            if (element.base64 != "") {
                element.base64 = this.decoder.getImageURL(element.base64);
            }
        });
        return aux;
    }
}
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CartService } from '../../tools/cart.service';



@Injectable({ providedIn: 'root' })
export class CartGuard implements CanActivate {
    constructor(
        private router: Router,
        private cart: CartService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.cart.getCart() != null || !this.cart.isCartValid()) {

            return true;
        }
        this.router.navigate(['/404']);

        return false;
    }
}
import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CartService } from 'src/app/core/tools/cart.service';
import { Role } from 'src/app/core/models/role';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';
import { Globals } from 'src/app/core/tools/globals.service';

@Component({
  selector: 'app-client-cart',
  templateUrl: './client-cart.component.html',
  styleUrls: ['./client-cart.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'width': '50vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-50vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-50vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '50vw',
            'right': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparent', [
      state('open', style({
        'width': '50vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '-50vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '-50vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '50vw',
            'left': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseMobile', [
      state('open', style({
        'width': '100vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-100vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-100vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '100vw',
            'right': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparentMobile', [
      state('open', style({
        'width': '0vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '0vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '0vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '0vw',
            'left': '0'
          }),
        ),
      ]),
    ])
  ],
})
export class ClientCartComponent implements OnInit, OnChanges {

  @Output() public onClose: EventEmitter<any> = new EventEmitter();
  @Input() show: boolean;

  showSidebav: boolean = false;

  target = document.querySelector("body");

  cartItems = [];

  currentUser;
  role;

  isCartValid = false;

  deliveryRestrictions: any;

  isMobile = false;

  constructor(private auth: LocalAuthService, private cart: CartService, private globals: Globals) {
    this.currentUser = this.auth.currentUserValue;
    this.role = Role;
  }

  ngOnInit() {
    this.reloadCart();

    this.checkIsMobile();

    this.getCart();
    this.checkCartValidity();

    this.getRestrictions();
  }

  ngOnChanges() {
    if (this.show) {
      disableBodyScroll(this.target);
    }
  }

  reloadCart() {
    this.cart.cartObservable.subscribe(
      () => {
        this.getCart();
        this.checkCartValidity();
      }
    );
  }

  hide(value): void {
    enableBodyScroll(this.target);
    this.onClose.emit(value);
  }

  getCart() {
    this.cartItems = this.cart.getCart();
  }

  getRestrictions() {
    this.deliveryRestrictions = this.globals.getDeliveryRestrictions();
  }

  checkCartValidity() {
    this.isCartValid = this.cart.isCartValid();
  }

  increaseQuantity(item) {
    this.cart.increase(item);
  }

  decreaseQuantity(item) {
    this.cart.decrease(item);
  }

  removeProduct(item) {
    this.cart.remove(item);
  }

  checkIsMobile() {
    if (window.innerWidth <= 992) {
      this.isMobile = true;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
@Pipe({
    name: 'showStatus'
})
export class ShowStatusPipe implements PipeTransform {

    constructor() {

    }

    transform(value: any): any {
        switch (value) {
            case 1: {
                return "En proceso";
            }
            case 2: {
                return "Terminado en espera de envío";
            }
            case 3: {
                return "En camino";
            }
            case 4: {
                return "Entregada";
            }
            case 5: {
                return "Cancelada";
            }
            case 6: {
                return "En espera de reembolso";
            }
            case 7: {
                return "Reembolso fallido";
            }
        }
    }
}
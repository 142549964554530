import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable()
export class AESService {

    

    constructor() {
    }

    encrypt(string){
        return CryptoJS.AES.encrypt(string,environment.apiKey).toString();
    }

    decrypt(string){
        if(string != null){
            return CryptoJS.AES.decrypt(string,environment.apiKey).toString(CryptoJS.enc.Utf8);
        }
        return null;
    }
}
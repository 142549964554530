import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { trigger, state, style, transition, animate, stagger, query } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LoginUseCases } from './use-cases/login-use-cases';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'max-height': '100vh',
        // 'opacity': '1',
        // 'visibility': 'visible'
      })),
      state('closed', style({
        'max-height': '0',
        // 'opacity': '0',
        // 'visibility': 'hidden'
      })),
      transition('open => closed', [
        // animate('400ms ease-in-out', style({
        //   'opacity': '0'
        // })),
        animate('600ms ease-in-out',
          style({
            'max-height': '0'
          }),
        ),
        query('.btn-close', [
          stagger(-30, [
            animate('600ms ease-in-out',
              style({
                'opacity': '0',
                'visibility': 'hidden'
              })
            ),
          ])
        ])
        // animate('700ms ease-in-out', style({
        //   'visibility': 'hidden'
        // }))
      ]),
      transition('closed => open', [
        // animate('1ms ease-in-out', style({
        //   'visibility': 'visible'
        // })),
        animate('600ms ease-in-out',
          style({
            'max-height': '100vh'
          }),
        ),
        query('.btn-close', [
          stagger(-30, [
            animate('600ms ease-in-out',
              style({
                'opacity': '1',
                'visibility': 'visible'
              })
            ),
          ])
        ])
        // animate('600ms ease-in-out', style({
        //   'max-height': '100vh'
        // })),
        // animate('800ms ease-in-out', style({
        //   'opacity': '1'
        // }))
      ]),
    ]),
  ],
  providers: [LoginUseCases]
})
export class LoginComponent implements OnInit, OnDestroy {

  @Output() public onClose: EventEmitter<any> = new EventEmitter();

  @Input() show: boolean;

  loginForm: FormGroup;
  passwordForm: FormGroup;

  hidePassword: boolean = true;

  target = document.querySelector("body");

  showPasswordLayout = false;

  sendingPasswordEmail = false;

  passwordEmailSub = new Subscription();

  constructor(private auth: LocalAuthService,
    private builder: FormBuilder,
    private regex: RegexService,
    private useCases: LoginUseCases,
    private reloadService: ReloadService) { }

  ngOnInit() {
    this.setReloadData();

    this.setValidators();
  }

  ngOnDestroy() {
    this.passwordEmailSub.unsubscribe();
  }

  setReloadData() {
    this.passwordEmailSub = this.reloadService.confirmationAddSuccess.subscribe(() => {
      this.sendingPasswordEmail = false;
    });
  }

  ngOnChanges() {
    if (this.show) {
      disableBodyScroll(this.target);
    }
  }

  login(form: FormGroup) {
    var isValid = form.valid;
    var value = form.value;

    if (isValid) {
      this.auth.login(value);
    }
  }

  loginWithGoogle() {
    this.auth.loginWithGoogle();
  }

  loginWithFacebook() {
    this.auth.loginWithFacebook();
  }

  onForgottenPassword(form: FormGroup) {
    var isValid = form.valid;
    var value = form.value;

    if (isValid) {
      this.passwordEmailSub.unsubscribe();
      this.sendingPasswordEmail = true;
      this.useCases.changePassword(value.email);
    }
  }

  hide(): void {
    this.showPasswordLayout = false;
    enableBodyScroll(this.target);
    this.onClose.emit(false);
  }

  toggleForgottenPassword() {
    this.showPasswordLayout = !this.showPasswordLayout;
  }



  setValidators() {
    this.loginForm = this.builder.group({
      email: ["", Validators.compose([Validators.email, Validators.required])],
      password: ["", Validators.compose([Validators.pattern(this.regex.passwordRegex), Validators.required])],
    });

    this.passwordForm = this.builder.group({
      email: ["", Validators.compose([Validators.email, Validators.required])],
    });
  }

}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class DocumentsUseCases {
    constructor(private reloadService: ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    editPrivacy(document) {
        this.postService.addData(document, `${Endpoints.documentsPrivate}privacy/`);
    }

    editTerms(document) {
        this.postService.addData(document, `${Endpoints.documentsPrivate}terms/`);
    }

    editCancellation(document) {
        this.postService.addData(document, `${Endpoints.documentsPrivate}cancel/`);
    }

    retrieve() {
        var result = this.getService.retrieveData(Endpoints.documents);
        result.subscribe(
            (result) => this.setContentData(result)
        );
    }
    private setContentData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateDocuments(res.data);
    }
}   
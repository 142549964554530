import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterProducts',
    pure: false
})
export class ProductsFilterByCategoryPipe implements PipeTransform {
    transform(value: any, categoriesArray): any {
        if (!categoriesArray || categoriesArray.length <= 0) return value;
        if(value != null){
            var filters = Object.entries(Object.assign({}, ...categoriesArray));
            var res = value.filter(o => filters.every(([k,v]) => o.category[k] === v));
            return res;
        }
        return value;
    }
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CarouselUseCases } from './use-cases/carousel-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel-administration',
  templateUrl: './carousel-administration.component.html',
  styleUrls: ['./carousel-administration.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
  providers: [CarouselUseCases]
})
export class CarouselAdministrationComponent implements OnInit, OnDestroy {

  carouselForm: FormGroup;

  showModal: boolean = false;

  target = document.querySelector("body");

  fileData: File = null;

  previewUrl: any;

  carouselImages = [];

  carouselImagesBackup = [];

  showAddButton = false;

  searchingImages = false;

  showSidenav = false;

  getSub = new Subscription();
  editSub = new Subscription();
  deleteSub = new Subscription();
  dataSub = new Subscription();

  constructor(private reloadService: ReloadService, private builder: FormBuilder, private useCases: CarouselUseCases, private dataService: DataService) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.setReloadData();

    this.setValidators();

    this.searchingImages = true;
    this.useCases.retrieve();
    this.getCarouselImages();
  }

  ngOnDestroy(): void {
    this.getSub.unsubscribe();
    this.editSub.unsubscribe();
    this.deleteSub.unsubscribe();
    this.dataSub.unsubscribe();
  }

  setReloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );
    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onAddSucess()
    );
    this.deleteSub = this.reloadService.confirmationDeleteUsingBodySuccess.subscribe(
      () => this.onDeleteSucess()
    );
  }

  onGetSuccess() {
    this.showAddButton = true;
    this.searchingImages = false;
  }

  onAddSucess() {
    this.toggleModal(false);
    this.searchingImages = true;
    this.useCases.retrieve();
  }

  onDeleteSucess() {
    this.searchingImages = true;
    this.useCases.retrieve();
  }

  getCarouselImages() {
    this.dataSub = this.dataService.currentCarouselImages.subscribe((data) => {
      this.carouselImages = Array.from(data);
    });
  }

  toggleModal(isOpening) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      return;
    }
    this.resetForm();
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  resetForm() {
    this.carouselImages = [];
    this.previewUrl = "";
    this.carouselForm.reset({
      files:null
    });
  }

  emptyBase64(value) {
    var aux = JSON.parse(JSON.stringify(value))
    aux.carousel.images.forEach(element => {
      element.base64 = "hola"
    });
    return aux;
  }

  deleteImage(carousel, image) {
    var aux = {
      carousel: carousel,
      carouselImage: image
    };
    this.useCases.delete(this.emptyBase64(aux))
  }

  addImage(form) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      value.carousel = this.carouselImages[0];

      this.useCases.add(this.emptyBase64(value));
    }
  }

  onImageChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var reader = new FileReader();

    var type = this.fileData.type;

    var extension = this.fileData.name.split('.').pop();

    if (type.match(/image\/*/) != null) {
      reader.readAsDataURL(this.fileData);

      var self = this;
      reader.onloadend = function () {
        var file = reader.result.toString();
        var regex = /data:(.*);base64,/;
        var image = [{
          base64: file.replace(regex, ""),
          fileType: {
            id: 1,
            name: "Image " + extension,
            extension: extension,
            contentType: type
          }
        }];

        self.carouselForm.get("files").setValue(image);

        self.previewUrl = reader.result;
      }
    }
  }

  setValidators() {
    this.carouselForm = this.builder.group({
      carousel: [""],
      files: ["", Validators.required]
    });
  }
}

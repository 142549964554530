import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DataService {

  private productsList = new Subject<any>();
  currentProductsList = this.productsList.asObservable();

  updateProductsList(message:any){
    this.productsList.next(message);
  }

  private categoriesList = new Subject<any>();
  currentCategoriesList = this.categoriesList.asObservable();

  updateCategoriesList(message:any){
    this.categoriesList.next(message);
  }

  private companyData = new Subject<any>();
  currentCompanyData = this.companyData.asObservable();

  updateCompanyData(message:any){
    this.companyData.next(message);
  }

  private carouselImages = new Subject<any>();
  currentCarouselImages = this.carouselImages.asObservable();

  updateCarouselImages(message:any){
    this.carouselImages.next(message);
  }

  private orders = new Subject<any>();
  currentOrders = this.orders.asObservable();

  updateOrders(message:any){
    this.orders.next(message);
  }

  private excelOrders = new Subject<any>();
  currentExcelOrders = this.excelOrders.asObservable();

  updateExcelOrders(message:any){
    this.excelOrders.next(message);
  }

  private pdfOrders = new Subject<any>();
  currentPdfOrders = this.pdfOrders.asObservable();

  updatePdfOrders(message:any){
    this.pdfOrders.next(message);
  }

  private clientOrders = new Subject<any>();
  currentClientOrders = this.clientOrders.asObservable();

  updateClientOrders(message:any){
    this.clientOrders.next(message);
  }

  private orderStatus = new Subject<any>();
  currentOrderStatus = this.orderStatus.asObservable();

  updateOrderStatus(message:any){
    this.orderStatus.next(message);
  }

  private documents = new Subject<any>();
  currentDocuments = this.documents.asObservable();

  updateDocuments(message:any){
    this.documents.next(message);
  }

  private deliveryRestrictions = new Subject<any>();
  currentDeliveryRestrictions = this.deliveryRestrictions.asObservable();

  updateDeliveryRestrictions(message:any){
    this.deliveryRestrictions.next(message);
  }

  private periodSubscriptions = new Subject<any>();
  currentPeriodSubscriptions = this.periodSubscriptions.asObservable();

  updatePeriodSubscriptions(message:any){
    this.periodSubscriptions.next(message);
  }

  private clientRecurrentOrders = new Subject<any>();
  currentClientRecurrentOrders = this.clientRecurrentOrders.asObservable();

  updateClientRecurrentOrders(message:any){
    this.clientRecurrentOrders.next(message);
  }

  private clientActiveOrders = new Subject<any>();
  currentClientActiveOrders = this.clientActiveOrders.asObservable();

  updateClientActiveOrders(message:any){
    this.clientActiveOrders.next(message);
  }

  private usersList = new Subject<any>();
  currentUsersList = this.usersList.asObservable();

  updateUsersList(message:any){
    this.usersList.next(message);
  }

  private usersReport = new Subject<any>();
  currentUsersReport = this.usersReport.asObservable();

  updateUsersReport(message:any){
    this.usersReport.next(message);
  }

  private recurrentOrdersReport = new Subject<any>();
  currentRecurrentOrdersReport = this.recurrentOrdersReport.asObservable();

  updateRecurrentOrdersReport(message:any){
    this.recurrentOrdersReport.next(message);
  }

  private statesList = new Subject<any>();
  currentStateList = this.statesList.asObservable();

  updateStateList(message:any){
    this.statesList.next(message);
  }

  private postalCodes = new Subject<any>();
  currentPostalCodes = this.postalCodes.asObservable();

  updatePostalCodes(message:any){
    this.postalCodes.next(message);
  }
}
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';

@Injectable()
export class ClientRegisterUseCases {
    constructor(private postService:PostService, private dataService:DataService) {}

    register(client) {
        this.postService.addData(client,Endpoints.usersPublic);
    }
}   
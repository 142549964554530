import { Injectable } from '@angular/core';

// import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from 'file-saver';
import { DatePipe, CurrencyPipe } from '@angular/common';

const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_FILE_NAME = 'produccion';

@Injectable()
export class ExcelRecurrentReportService {

    title = "Listado de posibles pedidos";

    header = ["Fecha Entrega", "ID Pedido", "Nombre Producto", "Cantidad", "Precio Unitario", "Dirección Entrega", "Nombre", "Fecha Creación", "Periodo"];

    workbook;

    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) {
    }

    private exportAsExcel(data) {
        this.workbook = new Excel.Workbook();
        let worksheet = this.workbook.addWorksheet("Data");


            // Add new row
            let titleRow = worksheet.addRow([this.title]);
            // Set font, size and style in title row.
            titleRow.font = { name: 'Arial Black', family: 2, size: 16, bold: true };
    
            // Blank Row
            worksheet.addRow([]);
            //Add row with current date
            let subTitleRow = worksheet.addRow(['Fecha del reporte : ' + this.datePipe.transform(new Date(), 'dd/MMM/yyyy')]);
    
    
            worksheet.addRow([]);
            let subTitleRow2 = worksheet.addRow(["Listado de Produtos"])
            subTitleRow2.font = { name: 'Arial Black', family: 2, size: 12, bold: true };
    
            //Blank Row 
            worksheet.addRow([]);
            let headerRow = worksheet.addRow(this.header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCA00' },
                    bgColor: { argb: 'FF0000FF' }
                }
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });
    
            data.forEach(value => {
    
    
                value.products.forEach(item => {
    
                    var items = [];
                    items[0] = this.datePipe.transform(new Date(value.creationDate), 'dd/MMM/yyyy');
                    items[1] = value.idRecurrentOrder;
                    items[2] = item.product.name;
                    items[3] = item.quantity;
                    items[4] = this.currencyPipe.transform(item.product.price/100, 'MXN', 'symbol-narrow', '1.2-2');
                    items[5] = value.address;
    
                    if (value.user == null) {
                        items[6] = value.name;
                    } else {
                        items[6] = value.user.name;
                    }
    
                    items[7] = this.datePipe.transform(new Date(value.timestamp), 'dd/MMM/yyyy');
                    items[8] = value.periodSubscription.name;
    
                    let row = worksheet.addRow(items);
                }
                );
            }
            )
    
        }
    saveExcelFile(data) {
        this.exportAsExcel(data);

        this.workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: EXCEL_TYPE });
            FileSaver.saveAs(blob, `${EXCEL_FILE_NAME}${EXCEL_EXTENSION}`);
        });
    }
}
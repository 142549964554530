import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ProductsAdminUseCases } from './use-cases/products-admin-use-cases';
import { DeleteDialog } from 'src/app/common/dialog/delete-dialog';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDialog } from './dialog/confirm-dialog';
import { DeleteWithObjectDialog } from 'src/app/common/dialog-delete-with-object/delete-dialog';

@Component({
  selector: 'app-products-administration',
  templateUrl: './products-administration.component.html',
  styleUrls: ['./products-administration.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
  providers: [ProductsAdminUseCases, DeleteDialog]
})
export class ProductsAdministrationComponent implements OnInit, OnDestroy {

  productsList: Observable<any>;
  categoriesList: Observable<any>;
  categoriesSelect = new FormControl();

  fileData: File = null;
  previewUrl = [];
  fileUploadProgress: string = null;

  productForm: FormGroup;

  showModal: boolean = false;

  showInfoModal: boolean = false;

  target = document.querySelector("body");

  searchingProducts: boolean = false;

  searchFilter = "";

  showSidenav = false;

  imageProduct = "";

  selectedProduct;

  weightList = [0, 1];

  isChangeImages = true;

  getSub = new Subscription();
  addSub = new Subscription();
  editSub = new Subscription();
  deleteSub = new Subscription();
  disableSub = new Subscription();
  disableModalSub = new Subscription();
  deleteModalSub = new Subscription();

  constructor(private sanitize: DomSanitizer, private decoder: Base64Decoder, private dialog: MatDialog, private reloadService: ReloadService, private dataService: DataService, private useCases: ProductsAdminUseCases, private regex: RegexService, private builder: FormBuilder) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.reloadData();
    this.setValidators();
    
    this.useCases.retrieveCategories();
    this.getCategories();

    this.searchingProducts = true;
    this.useCases.retrieveProducts();
    this.getProducts();
  }

  ngOnDestroy() {
    this.getSub.unsubscribe();
    this.addSub.unsubscribe();
    this.editSub.unsubscribe();
    this.deleteSub.unsubscribe();
    this.disableSub.unsubscribe();
    this.disableModalSub.unsubscribe();
    this.deleteModalSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );
    this.addSub = this.reloadService.confirmationAddSuccess.subscribe(
      () => this.onAddSucess()
    );
    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );
    this.deleteSub = this.reloadService.confirmationDeleteUsingBodySuccess.subscribe(
      () => this.onDeleteSuccess()
    );

    this.disableModalSub = this.reloadService.confirmationDisableSuccess.subscribe(
      () => this.onDisableSuccess()
    );
  }

  onGetSuccess() {
    this.searchingProducts = false;
  }

  onAddSucess() {
    this.toggleModal(false);
    this.searchingProducts = true;
    this.clearForm();
    this.clearImages();
    this.useCases.retrieveProducts();
  }

  onEditSuccess() {
    this.toggleModal(false);
    this.searchingProducts = true;
    this.clearForm();
    this.clearImages();
    this.useCases.retrieveProducts();
  }

  onDeleteSuccess() {
    this.searchingProducts = true;
    this.useCases.retrieveProducts();
  }

  onDisableSuccess() {
    this.searchingProducts = true;
    this.useCases.retrieveProducts();
  }

  getCategories() {
    this.categoriesList = this.dataService.currentCategoriesList;
  }

  getProducts() {
    this.productsList = this.dataService.currentProductsList;
  }

  onVisibilityChange(e, product) {
    var initialState = e.checked;

    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { product: product }
    });

    this.disableModalSub = dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        var productResult = result.product;
        productResult.visible = initialState;
        this.useCases.disable(productResult);
        return;
      }
      e.source._checked = !initialState;
    });
  }

  toggleInfoModal(isOpening, data?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showInfoModal = !this.showInfoModal;

      if (data != undefined) {
        this.selectedProduct = data;
        this.imageProduct = this.selectedProduct.imageLinks[0];
      }

      return;
    }
    this.clearImages();
    this.resetForm();
    this.isChangeImages = true;
    enableBodyScroll(this.target);
    this.showInfoModal = !this.showInfoModal;
  }

  changeImage(image) {
    this.imageProduct = image;
  }

  toggleModal(isOpening, data?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (data != undefined) {
        this.isChangeImages = false;
        this.setEditData(data);
      }

      return;
    }
    this.clearImages();
    this.resetForm();
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  setEditData(data) {
    this.productForm.patchValue({
      id: data.id,
      name: data.name,
      price: Number(data.price) / 100,
      description: data.description,
      category: data.category,
      weight: data.weight
    });

    data.imageLinks.forEach(element => {
      this.previewUrl.push(element);
    });
  }

  registerProduct(form) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      var dataFormated = this.formatData(value);
      delete dataFormated.imageSelector;

      if (value.id != "") {
        if (dataFormated.files.length <= 0) {
          dataFormated.files = [];
        }
        this.useCases.edit(dataFormated);
        return;
      }
      delete dataFormated.id;
      this.useCases.add(dataFormated);
    }
  }

  formatData(data) {
    data.name = data.name.trim();
    data.description = data.description.trim();

    data.price = Number(data.price) * 100;
    return data;
  }

  onDelete(product) {
    delete product.imageLinks;
    product.files = [];
    const dialogRef = this.dialog.open(DeleteWithObjectDialog, {
      width: '250px',
      data: { object: product }
    });

    this.deleteModalSub = dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.useCases.delete(result);
      }
    });
  }

  onImageChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var reader = new FileReader();

    var type = this.fileData.type;

    var extension = this.fileData.name.split('.').pop();

    if (type.match(/image\/*/) != null) {
      reader.readAsDataURL(this.fileData);

      var self = this;
      reader.onloadend = function () {
        var file = reader.result.toString();
        var regex = /data:(.*);base64,/;
        var image = {
          base64: file.replace(regex, ""),
          fileType: {
            id: 1,
            name: "Image " + extension,
            extension: extension,
            contentType: type
          }
        };

        self.addImageElement(image);
        self.previewUrl.push(reader.result);
      }
    }
  }

  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl.push(reader.result);
    }
  }

  clearImages() {
    this.previewUrl = [];
    this.clearFormArray();
  }

  changeImages() {
    this.clearImages();
    this.isChangeImages = true;
  }

  setImageElement(data) {
    if (data != undefined) {
      return this.builder.group({
        base64: [data.base64],
        fileType: [{
          id: data.fileType.id,
          name: data.fileType.name,
          extension: data.fileType.extension,
          contentType: data.fileType.contentType
        }]
      });
    }
  }

  addImageElement(data) {
    const control = <FormArray>this.productForm.controls['files'];
    control.push(this.setImageElement(data));
  }

  removeSpeakerElement(i: number, group) {
    const control = <FormArray>group.controls['files'];
    control.removeAt(i);
  }

  clearFormArray() {
    const control = <FormArray>this.productForm.controls['files'];
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  clearForm() {
    this.previewUrl = [];
    this.resetForm();
  }

  resetForm() {
    this.productForm.reset({
      id: "",
      name: "",
      shortDesc: "",
      price: "",
      description: "",
      files: this.builder.array([]),
      imageSelector: "",
      category: "",
      weight: ""
    });
  }

  setValidators() {
    this.productForm = this.builder.group({
      id: [""],
      name: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      price: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.currencyRegex)])],
      description: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.commentsRegex)])],
      files: this.builder.array([]),
      imageSelector: [""],
      category: ["", Validators.required],
      weight: ["", Validators.required]
    });
  }

  compareWithFn(item1, item2) {
    return item1 && item2 ? (item1.id === item2.id) : item1 === item2;
  }

  getImageURL(string) {
    var url = URL.createObjectURL(this.decoder.getBlop(string));
    return this.sanitize.bypassSecurityTrustUrl(url);
  }
}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class ProductsUseCases {
    constructor(private reloadService:ReloadService, private getService:GetService, private dataService:DataService) {}

    retrieveProducts() {
        var result = this.getService.retrieveData(Endpoints.productsPublic);
        result.subscribe(
            (result) => this.setProductsContentData(result)
        );
    }
    private setProductsContentData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateProductsList(res.data);
    }

    retrieveProductsByCategory(data) {
        var ids = data.join(',');
        var result = this.getService.retrieveData(`${Endpoints.productsPublic}category?ids=${ids}`);
        result.subscribe(
            (result) => this.setProductsByCategoryData(result)
        );
    }
    private setProductsByCategoryData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateProductsList(res.data);
    }

    retrieveCategories() {
        var result = this.getService.retrieveData(Endpoints.categoriesPublic);
        result.subscribe(
            (result) => this.setCategoriesContentData(result)
        );
    }
    private setCategoriesContentData(res) {
        this.dataService.updateCategoriesList(res.data);
    }

    retrieveDeliveryRestrictions(){
        this.getService.retrieveData(Endpoints.deliveryRestrictions).subscribe(
            (data) => this.setDeliveryRestrictionsData(data)
        )
    }

    private setDeliveryRestrictionsData(res) {
        this.dataService.updateDeliveryRestrictions(res.data);
    }
}   
import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class ClientProfileUseCases {
    constructor(
        private reloadService: ReloadService,
        private getService: GetService,
        private dataService: DataService,
        private postService: PostService
    ) { }

    edit(user) {
        this.postService.editDataReturningObject(user, Endpoints.usersPrivate);
    }

    cancelRecurrentOrder(id) {
        this.postService.editDataWithoutBody(`${Endpoints.recurrentOrderStatus}?orderId=${id}`);
    }

    changePassword(email) {
        this.postService.addData(null, `${Endpoints.requestChangePassword}?email=${email}`);
    }

    retrieveActiveOrders(email) {
        var result = this.getService.retrieveData(`${Endpoints.orderPrivateClientGet}?email=${email}`);
        result.subscribe(
            (result) => this.setActiveOrdersData(result)
        );
    }
    private setActiveOrdersData(res) {
        this.dataService.updateClientActiveOrders(res.data);
    }

    retrieveOrders(email) {
        var result = this.getService.retrieveData(`${Endpoints.orderPrivateClientGet}?email=${email}`);
        result.subscribe(
            (result) => this.setOrdersData(result)
        );
    }
    private setOrdersData(res) {
        this.dataService.updateClientOrders(res.data);
    }

    retrieveRecurrentOrders(email) {
        var result = this.getService.retrieveData(`${Endpoints.recurrentOrderPrivateClientGet}?email=${email}`);
        result.subscribe(
            (result) => this.setRecurrentOrdersData(result)
        );
    }
    private setRecurrentOrdersData(res) {
        this.dataService.updateClientRecurrentOrders(res.data);
    }
}   
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { GetService } from 'src/app/core/httpServices/get.service';
import { PostService } from 'src/app/core/httpServices/post.service';

@Injectable()
export class CheckoutUseCases {
    constructor(private postService:PostService, private getService:GetService, private dataService:DataService) {}

    addNormal(order){
        this.postService.addData(order,Endpoints.orderPrivatePost);
    }

    addRecurrent(order){
        this.postService.addData(order,Endpoints.recurrentOrder);
    }

    addNormalLocal(order){
        this.postService.addData(order,Endpoints.localOrderPrivatePost);
    }

    retrievePeriodSubscriptions(){
        this.getService.retrieveData(Endpoints.periodSubscriptions).subscribe(
            (data) => this.setPeriodSubscriptions(data)
        );
    }

    setPeriodSubscriptions(data){
        this.dataService.updatePeriodSubscriptions(data.data);
    }

    retrieveStates(){
        this.getService.retrieveData(Endpoints.statesPublic).subscribe(
            (data) => this.setStates(data)
        );
    }

    setStates(data){
        this.dataService.updateStateList(data.data);
    }

    retrievePostalCodes(){
        this.getService.retrieveData(Endpoints.postalCodesPrivate).subscribe(
            (data) => this.setPostalCodes(data)
        );
    }

    setPostalCodes(data){
        this.dataService.updatePostalCodes(data.data);
    }
}   
import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'width': '25vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-25vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-25vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '25vw',
            'right': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparent', [
      state('open', style({
        'width': '75vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '-75vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '-75vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '75vw',
            'left': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseMobile', [
      state('open', style({
        'width': '75vw',
        'right': '0'
      })),
      state('closed', style({
        'width': '0',
        'right': '-75vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'right': '-75vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '75vw',
            'right': '0'
          }),
        ),
      ]),
    ]),
    trigger('openCloseTransparentMobile', [
      state('open', style({
        'width': '25vw',
        'left': '0'
      })),
      state('closed', style({
        'width': '0',
        'left': '-25vw'
      })),
      transition('open => closed', [
        animate('600ms ease-in-out',
          style({
            'width': '0',
            'left': '-25vw'
          }),
        ),
      ]),
      transition('closed => open', [
        animate('600ms ease-in-out',
          style({
            'width': '25vw',
            'left': '0'
          }),
        ),
      ]),
    ])
  ],
})
export class ProfileDetailsComponent implements OnInit, OnChanges {

  @Output() public onClose: EventEmitter<any> = new EventEmitter();
  @Output() public onEdit: EventEmitter<any> = new EventEmitter();
  @Output() public onChangePassword: EventEmitter<any> = new EventEmitter();
  @Input() show: boolean;
  @Input() data: any;

  isMobile = false;

  constructor() { }

  ngOnInit() {
    this.checkIsMobile();
  }

  ngOnChanges() {
    if (this.show) {
      // disableBodyScroll(this.target);
    }
  }

  hide(value): void {
    // enableBodyScroll(this.target);
    this.onClose.emit(value);
  }

  onEditUser(value) {
    this.onEdit.emit(value);
  }

  onChangeUserPassword(value) {
    this.onChangePassword.emit(value);
  }

  checkIsMobile() {
    if (window.innerWidth <= 992) {
      this.isMobile = true;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';
@Pipe({
    name: 'showTotal'
})
export class ShowTotalPipe implements PipeTransform {

    transform(value: any): any {
        if(value != null){
            return value.reduce((sum, item) => sum += ((item.price * item.quantity)/100) ,0);
        }
        return 0;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Globals } from '../tools/globals.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GetService {
    url: string;

    constructor(public toast: ToastrService, public http: HttpClient, private globals: Globals) {
        this.getURL();
    }

    getURL() {
        this.url = this.globals.getURL();
    }

    retrieveData(endpoint) {
        var subject = new Subject<any>();
        this.http.get(this.url + endpoint)
            .subscribe(
                (result) => subject.next(result),
                (failure) => this.handleError(failure)
            );
        return subject.asObservable();
    }

    retrieveDataWithBody(data, endpoint) {
        var subject = new Subject<any>();
        this.http.get(this.url + endpoint, data)
            .subscribe(
                (result) => subject.next(result),
                (failure) => this.handleError(failure)
            );
        return subject.asObservable();
    }

    handleError(failure) {
        this.showError(failure.message);
    }

    showError(message) {
        this.toast.error(message);
    }
}
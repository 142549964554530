import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoriesUseCases } from './use-cases/categories-use-cases';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { MatDialog } from '@angular/material';
import { DeleteDialog } from 'src/app/common/dialog/delete-dialog';
import { CategoryImage } from 'src/app/core/models/categoryImage';
import { DomSanitizer } from '@angular/platform-browser';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';

@Component({
  selector: 'app-categories-administration',
  templateUrl: './categories-administration.component.html',
  styleUrls: ['./categories-administration.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
  providers: [CategoriesUseCases, DeleteDialog]
})
export class CategoriesAdministrationComponent implements OnInit, OnDestroy {

  categoryForm: FormGroup;

  categoriesList: Observable<any>;

  showModal: boolean = false;

  target = document.querySelector("body");

  searchFilter = "";

  searchingCategories: boolean = false;

  fileData: File = null;

  previewUrl: any;

  carouselImages = [];

  weightList = [0, 1];

  getSub = new Subscription();
  addSub = new Subscription();
  deleteSub = new Subscription();
  editSub = new Subscription();
  dialogSub = new Subscription();

  constructor(private sanitize: DomSanitizer, private decoder: Base64Decoder, public dialog: MatDialog, private dataService: DataService, private reloadService: ReloadService, private regex: RegexService, private useCases: CategoriesUseCases, private builder: FormBuilder) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.reloadData();
    this.setValidators();

    this.searchingCategories = true;
    this.useCases.retrieve();
    this.getCategories();
  }

  ngOnDestroy(){
    this.getSub.unsubscribe();
    this.addSub.unsubscribe();
    this.deleteSub.unsubscribe();
    this.editSub.unsubscribe();
    this.dialogSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );

    this.addSub = this.reloadService.confirmationAddSuccess.subscribe(
      () => this.onAddSuccess()
    );

    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );

    this.deleteSub = this.reloadService.confirmationDeleteSuccess.subscribe(
      () => this.onDeleteSuccess()
    );
  }

  onGetSuccess() {
    this.searchingCategories = false;
  }

  onAddSuccess() {
    this.toggleModal(false);
    this.searchingCategories = true;
    this.useCases.retrieve();
  }

  onEditSuccess() {
    this.toggleModal(false);
    this.searchingCategories = true;
    this.useCases.retrieve();
  }

  onDeleteSuccess() {
    this.useCases.retrieve();
  }

  getCategories() {
    this.categoriesList = this.dataService.currentCategoriesList;
  }

  toggleModal(isOpening, content?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (content != undefined) {
        this.setEditData(content);
      }

      return;
    }
    this.resetForm();
    this.previewUrl = "";
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  setEditData(data) {
    this.categoryForm.patchValue({
      id: data.id,
      name: data.name,
      weight: data.weight
    });

    this.previewUrl = this.getImageURL(data.linkImage);
  }

  registerCategory(form) {
    var valid = form.valid;
    var value = form.value;

    var categoryImage: CategoryImage = new CategoryImage();

    categoryImage.customFile = value.customFile;
    delete value.customFile;
    categoryImage.category = value;

    if (valid) {
      if (value.id != "") {
        this.useCases.edit(categoryImage);
        return;
      }
      delete categoryImage.category.id;
      this.useCases.add(categoryImage);
    }
  }

  onImageChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var reader = new FileReader();

    var type = this.fileData.type;

    var extension = this.fileData.name.split('.').pop();

    if (type.match(/image\/*/) != null) {
      reader.readAsDataURL(this.fileData);

      var self = this;
      reader.onloadend = function () {
        var file = reader.result.toString();
        var regex = /data:(.*);base64,/;
        var image = [{
          base64: file.replace(regex, ""),
          fileType: {
            id: 1,
            name: "Image " + extension,
            extension: extension,
            contentType: type
          }
        }];

        self.categoryForm.get("customFile").setValue(image);

        self.previewUrl = reader.result;
      }
    }
  }

  onDelete(category) {
    const dialogRef = this.dialog.open(DeleteDialog, {
      width: '250px',
      data: { name: category.name, id: category.id }
    });

    this.dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.useCases.delete(result);
      }
    });
  }

  resetForm() {
    this.categoryForm.reset({
      id: ""
    });
  }

  setValidators() {
    this.categoryForm = this.builder.group({
      id: [""],
      name: ["", Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      customFile: [[]],
      weight: ["", Validators.required]
    });
  }

  getImageURL(string) {
    var url = URL.createObjectURL(this.decoder.getBlop(string));
    return this.sanitize.bypassSecurityTrustUrl(url);
  }
}

import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class ReportsAdminUseCases {
    constructor(private reloadService:ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    edit(product) {
        this.postService.editData(product, Endpoints.orderPrivatePost);
    }

    updateStatus(idOrder,idStatus) {
        this.postService.editDataWithoutBody(`${Endpoints.orderStatusUpdate}?idOrder=${idOrder}&idStatus=${idStatus}`);
    }

    delete(product){
        this.postService.deleteUsingBody(product,Endpoints.productsDelete);
    }

    generateUsersReport() {
        var result = this.getService.retrieveData(`${Endpoints.usersPrivateReport}`);
        result.subscribe(
            (result) => this.setUsersReportData(result)
        );
    }
    private setUsersReportData(res) {
        this.dataService.updateUsersReport(res.data);
    }

    generateRecurrentOrdersReport(beginDate, endDate) {
        var result = this.getService.retrieveData(`${Endpoints.orderRecurrentPrivate}report?beginDate=${beginDate}&endDate=${endDate}`);
        result.subscribe(
            (result) => this.setRecurrentOrdersReport(result)
        );
    }
    private setRecurrentOrdersReport(res) {
        this.dataService.updateRecurrentOrdersReport(res.data);
    }
}   
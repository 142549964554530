import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { CartService } from 'src/app/core/tools/cart.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { StripeService, StripeCardComponent, StripeCardNumberComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeCardElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent
} from '@stripe/stripe-js';
import { Globals } from 'src/app/core/tools/globals.service';
import { Order } from 'src/app/core/models/order';
import { User } from 'src/app/core/models/user';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { CheckoutUseCases } from './use-cases/checkout-use-cases';
import { OnlineOrder } from 'src/app/core/models/online-order';

import { RegexService } from 'src/app/core/tools/regex.service';
import { LocalAuthService } from 'src/app/core/authServices/auth.service';
import { Role } from 'src/app/core/models/role';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ShowFullTotalPipe } from 'src/app/core/pipes/show-full-total.pipe';
import { ShowTotalPipe } from 'src/app/core/pipes/show-total.pipe';
import { MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [Order, OnlineOrder, User, CheckoutUseCases],
  encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent implements OnInit, OnDestroy {

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        padding: '1',
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };

  // @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  @ViewChild(StripeCardNumberComponent, { static: false }) card: StripeCardNumberComponent;

  isLinear = true;
  cartFormGroup: FormGroup;
  addressForm: FormGroup;
  paymentForm: FormGroup;
  orderForm: FormGroup;

  cartItems = [];

  user: User;

  twoDaysInMs = 172800000;

  minDate: Date = new Date(Date.now() + this.twoDaysInMs);

  order: Order = new Order();

  onlineOrder: OnlineOrder = new OnlineOrder();

  userMarker;

  noValidAddress = false;

  validDistanceInMeters = 0;

  orderAddress = "";
  orderDistance = 0;

  addressControl: FormControl = new FormControl();

  searchAddressResults = [];

  isRecurrent = false;

  deliveryCost = 0;

  currentUser;
  role;

  periodSubscriptions = [];

  isPCSearched = false;
  isPickup = false;

  pickupAddress = "";

  deliveryRestrictions;

  cartSub = new Subscription();
  reloadSub = new Subscription();
  periodListSub = new Subscription();
  tokenSub = new Subscription();
  statesSub = new Subscription();
  statesListSub = new Subscription();
  postalCodesListSub = new Subscription();
  postalCodesSub = new Subscription();

  statesList = [];

  minimumPurchase = 0;

  cartSubtotal = 0;

  postalCodes = [];

  isPcValid = false;

  isMobile = false;

  snackDuration = 2000;

  constructor(
    private router: Router,
    private dataService: DataService,
    private auth: LocalAuthService,
    private regex: RegexService,
    private useCases: CheckoutUseCases,
    private reloadService: ReloadService,
    private showFullTotal: ShowFullTotalPipe,
    private showTotal: ShowTotalPipe,
    private globals: Globals,
    private stripe: StripeService,
    private cart: CartService,
    private builder: FormBuilder,
    private toast: ToastrService,
    private snack: MatSnackBar) {
    this.currentUser = this.auth.currentUserValue;
    this.role = Role;
    this.checkIsMobile();
  }

  ngOnInit() {
    this.setValidators();
    this.initPostalCodeListener();
    this.setFormListeners();
    this.reloadData();

    this.getCart();
    this.getCartSubtotal();

    this.getUserData();

    this.getDeliveryRestrictions();

    this.useCases.retrievePeriodSubscriptions();
    this.getPeriodSubscriptions();

    this.useCases.retrieveStates();
    this.getStatesList();

    this.useCases.retrievePostalCodes();
    this.getPostalCodes();
  }

  ngOnDestroy() {
    this.cartSub.unsubscribe();
    this.reloadSub.unsubscribe();
    this.periodListSub.unsubscribe();
    this.tokenSub.unsubscribe();
    this.statesSub.unsubscribe();
    this.statesListSub.unsubscribe();
    this.postalCodesListSub.unsubscribe();
    this.postalCodesSub.unsubscribe();
  }

  reloadData() {
    this.cartSub = this.cart.cartObservable.subscribe(
      () => {
        this.getCart();
        this.getCartSubtotal();
      }
    );

    this.reloadSub = this.reloadService.confirmationAddSuccess.subscribe(
      () => this.onAddSuccess()
    );
  }

  getCartSubtotal() {
    this.cartSubtotal = this.showTotal.transform(this.cartItems);
  }

  getCart() {
    this.cartItems = this.cart.getCart();
  }

  onAddSuccess() {
    this.router.navigate(['/confirmacion-compra']);
    this.cart.clearCart();
  }

  getStatesList() {
    this.statesListSub = this.dataService.currentStateList.subscribe((data) => {
      this.statesList = data;
    });
  }

  getPostalCodes() {
    this.postalCodesListSub = this.dataService.currentPostalCodes.subscribe((data) => {
      this.postalCodes = data;
    });
  }

  getPeriodSubscriptions() {
    this.periodListSub = this.dataService.currentPeriodSubscriptions.subscribe((data) => this.periodSubscriptions = data);
  }

  getDeliveryRestrictions() {
    this.deliveryRestrictions = this.globals.getDeliveryRestrictions();
    this.setRestrictions();

  }

  setRestrictions() {
    this.minimumPurchase = this.deliveryRestrictions.minimumPurchase;
    this.deliveryCost = this.deliveryRestrictions.deliveryPrice;
    this.validDistanceInMeters = this.deliveryRestrictions.deliveryArea;
  }

  getUserData() {
    this.user = this.globals.getUserData();
  }

  saveCart(form) {
    this.onlineOrder.total = this.showFullTotal.transform(this.cartItems) * 100;
    this.onlineOrder.deliveryCost = this.deliveryCost;
    this.onlineOrder.orderProducts = this.cartItems;
  }

  setLocalAddress() {
    this.addressControl.setValue("53960");
    this.addressForm.patchValue({
      neighborhood: 'Lomas de San Isidro',
      exteriorNumber: '4',
      street: 'Santa Rosa',
      municipality: 'Naucalpan de Juárez',
      state: {
        id: "5f3e976ce9e2f431841e2f74",
        name: 'Estado de México'
      },
    });

    var pc = this.addressControl.value;

    this.isPickup = true;
    this.pickupAddress = `${this.addressForm.value.street} No. ${this.addressForm.value.exteriorNumber} ${(this.addressForm.value.interiorNumber != "" ? `int. ${this.addressForm.value.interiorNumber}` : "")} Col. ${this.addressForm.value.neighborhood} ${pc}, ${this.addressForm.value.municipality}, ${this.addressForm.value.state.name}`;
  }

  saveAddress(form) {
    var val = form.getRawValue();
    var valid = form.valid;

    if (valid) {
      var deliveryDate = val.deliveryDate;
      var pc = this.addressControl.value;

      var address = `${val.street} No. ${val.exteriorNumber} ${(val.interiorNumber != "" ? `int. ${val.interiorNumber}` : "")} Col. ${val.neighborhood} ${pc}, ${val.municipality}, ${val.state.name}`;

      this.onlineOrder.deliveryDate = deliveryDate.getTime();
      this.onlineOrder.distance = this.orderDistance;
      this.onlineOrder.address = address;
    }
  }

  savePaymentData(form) {
    var name = form.value.name;

    if (this.currentUser != null) {
      if (this.currentUser.rol == this.role.User) {
        this.isRecurrent = form.value.isRecurrent;
        this.order.periodSubscription = form.value.subscription;
        this.paymentForm.get('name').setValidators([]);
      }
      else {
        this.onlineOrder.name = name;
      }
    }
  }

  onStripeNumberChange(event: StripeCardNumberElementChangeEvent) {
    this.displayStripeError(event);
  }

  onStripeExpirityChange(event: StripeCardExpiryElementChangeEvent) {
    this.displayStripeError(event);
  }
  onStripeCVCChange(event: StripeCardCvcElementChangeEvent) {
    this.displayStripeError(event);
  }

  displayStripeError(event) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }

  generateToken(): void {
    var name = this.paymentForm.get('name').value;
    this.tokenSub = this.stripe
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          this.order.token = result.token.id;
          this.paymentForm.get('paymentValid').setValue(true);
          this.snack.open("Método validado exitosamente", null, { duration: this.snackDuration })
        } else if (result.error) {
          this.toast.error(result.error.message);
        }
      });
  }

  sendOrder() {
    this.onlineOrder.timestamp = Date.now();
    if (this.currentUser != null) {
      if (this.currentUser.rol == this.role.User) {
        this.onlineOrder.user = this.user;
        this.order.onlineOrder = this.onlineOrder;

        if (this.isRecurrent) {
          this.order.isRecurrent = this.isRecurrent;
          this.useCases.addRecurrent(this.order);

          return;
        }

        this.useCases.addNormal(this.order);

        return;
      }
      this.useCases.addNormalLocal(this.onlineOrder);
    }
  }

  increaseQuantity(item) {
    this.cart.increase(item);
  }

  decreaseQuantity(item) {
    this.cart.decrease(item);
  }

  removeProduct(item) {
    this.cart.remove(item);
  }

  initPostalCodeListener() {
    this.postalCodesSub = this.addressControl.valueChanges.subscribe(
      (val) => {
        if (val != "") {
          var res = this.postalCodes.filter((v) =>
            (v.postalCode == val)
          );

          if (val.length == 5) {
            var valid = (res.length > 0);
            this.isPcValid = valid;
            this.isPCSearched = true;
          }
        }
      }
    )
  }

  setFormListeners() {
    if (this.currentUser != null) {
      if (this.currentUser.rol == this.role.User) {
        this.paymentForm.get('isRecurrent').valueChanges.subscribe((val) => {
          if (((this.currentUser.orderId == false && val == true) || val == false)) {
            this.paymentForm.get('name').setValidators(Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)]));
            this.paymentForm.get('name').updateValueAndValidity();
            this.paymentForm.get('paymentValid').setValidators(Validators.requiredTrue);
            this.paymentForm.get('paymentValid').updateValueAndValidity();
          }
          else {
            this.paymentForm.get('name').clearValidators();
            this.paymentForm.get('name').updateValueAndValidity();
            this.paymentForm.get('paymentValid').clearValidators();
            this.paymentForm.get('paymentValid').updateValueAndValidity();
          }
        });
        return;
      }
      this.paymentForm.get('paymentValid').clearValidators();
      this.paymentForm.get('paymentValid').updateValueAndValidity();
    }
  }

  setValidators() {
    this.addressControl.setValidators(Validators.compose([Validators.required, Validators.min(0), Validators.maxLength(5), Validators.pattern(this.regex.pcRegex)]))

    this.cartFormGroup = this.builder.group({
      firstCtrl: ['']
    });

    this.addressForm = this.builder.group({
      neighborhood: ['', Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      exteriorNumber: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(this.regex.addressNumberRegex)])],
      interiorNumber: ['', Validators.compose([Validators.min(1), Validators.max(99999), Validators.pattern(this.regex.addressNumberRegex)])],
      street: ['', Validators.compose([Validators.required, Validators.pattern(this.regex.addressRegex)])],
      deliveryDate: ['', Validators.compose([Validators.required])],
      municipality: ['', Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      state: ['', Validators.compose([Validators.required])]
    });

    this.paymentForm = this.builder.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(this.regex.nameRegex)])],
      paymentValid: [false, Validators.requiredTrue],
      isRecurrent: [false],
      subscription: [""]
    });

    this.orderForm = this.builder.group({
      firstCtrl: ['']
    });
  }

  checkIsMobile() {
    if (window.innerWidth <= 992) {
      this.isMobile = true;
    }
  }
}

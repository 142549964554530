import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentsUseCases } from './use-cases/document-admin-use-cases';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Subscription } from 'rxjs';
import { Base64Decoder } from 'src/app/core/tools/base64-decoder.service';

@Component({
  selector: 'app-documents-administration',
  templateUrl: './documents-administration.component.html',
  styleUrls: ['./documents-administration.component.scss'],
  providers: [DocumentsUseCases],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class DocumentsAdministrationComponent implements OnInit, OnDestroy {

  showModal = false;

  target = document.querySelector("body");

  fileData: File = null;

  documents;

  pdfUrl;

  showDocument = false;

  searchingDocuments = false;

  updateData;
  updateType;

  getSub = new Subscription();
  editSub = new Subscription();
  dataSub = new Subscription();

  constructor(private decoder: Base64Decoder, private useCases: DocumentsUseCases, private dataService: DataService, private reloadService: ReloadService) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.searchingDocuments = true;
    this.useCases.retrieve();
    this.getDocuments();
  }

  ngOnDestroy(){
    this.getSub.unsubscribe();
    this.editSub.unsubscribe();
    this.dataSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );

    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );
  }

  onGetSuccess() {
    this.searchingDocuments = false;
  }

  onEditSuccess() {
    this.toggleModal(false);
  }

  getDocuments() {
    this.dataSub = this.dataService.currentDocuments.subscribe((data) => this.documents = data);
  }

  toggleDocument(type?) {
    if(type != undefined){
      switch (type) {
        case 1: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.termsAndConditions);
  
          break;
        }
        case 2: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.cancellationPolicies);
  
          break;
        }
        case 3: {
          this.pdfUrl = this.decoder.getPDFURL(this.documents.privacy);
  
          break;
        }
      }
      disableBodyScroll(this.target);
      this.showDocument = !this.showDocument;

      return;
    }
    enableBodyScroll(this.target);
    this.showDocument = !this.showDocument;
  }

  toggleModal(isOpening, type?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      this.updateType = type;

      return;
    }
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  onImageChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var reader = new FileReader();

    var type = this.fileData.type;

    var extension = this.fileData.name.split('.').pop();

    if (type == "application/pdf") {
      reader.readAsDataURL(this.fileData);

      var self = this;
      reader.onloadend = function () {
        var file = reader.result.toString();
        var regex = /data:(.*);base64,/;
        var document = {
          base64: file.replace(regex, ""),
          fileType: {
            id: 1,
            name: "Document " + extension,
            extension: extension,
            contentType: type
          }
        };

        self.updateData = document;
      }
    }
  }

  updateDocument() {
    switch (this.updateType) {
      case 1: {
        this.useCases.editTerms(this.updateData);

        break;
      }
      case 2: {
        this.useCases.editCancellation(this.updateData);

        break;
      }
      case 3: {
        this.useCases.editPrivacy(this.updateData);

        break;
      }
    }
  }
}

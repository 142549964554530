import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './common/home/home.component';
import { AboutComponent } from './common/about/about.component';
import { ProductsComponent } from './common/products/products.component';
import { ClientRegisterComponent } from './client/client-register/client-register.component';
import { ProductsAdministrationComponent } from './administration/products-administration/products-administration.component';
import { ClientProfileComponent } from './client/client-profile/client-profile.component';
import { CategoriesAdministrationComponent } from './administration/categories-administration/categories-administration.component';
import { CarouselAdministrationComponent } from './administration/carousel-administration/carousel-administration.component';
import { CheckoutComponent } from './client/checkout/checkout.component';
import { TermsConditionsComponent } from './common/terms-conditions/terms-conditions.component';
import { OrdersAdministrationComponent } from './administration/orders-administration/orders-administration.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { UsersAdministrationComponent } from './administration/users-administration/users-administration.component';
import { ReportsAdministrationComponent } from './administration/reports-administration/reports-administration.component';
import { AuthGuard } from './core/authServices/guards/auth.guard';
import { Role } from './core/models/role';
import { CompanyAdministrationComponent } from './administration/company-administration/company-administration.component';
import { DocumentsAdministrationComponent } from './administration/documents-administration/documents-administration.component';
import { PurchaseConfirmationComponent } from './client/purchase-confirmation/purchase-confirmation.component';
import { CartGuard } from './core/authServices/guards/cart.guard';
import { ChangePasswordComponent } from './common/change-password/change-password.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'productos', component: ProductsComponent },
  { path: 'registro', component: ClientRegisterComponent },
  { path: 'cambiar-contrasena/:token', component: ChangePasswordComponent },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthGuard, CartGuard],
    data: { roles: [Role.Admin, Role.User] }
  },
  {
    path: 'mi-perfil',
    component: ClientProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User] }
  },
  {
    path: 'confirmacion-compra',
    component: PurchaseConfirmationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Admin] }
  },
  {
    path: 'administracion-productos',
    component: ProductsAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-categorias',
    component: CategoriesAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-carrusel',
    component: CarouselAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-pedidos',
    component: OrdersAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-usuarios',
    component: UsersAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'reportes',
    component: ReportsAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-nosotros',
    component: CompanyAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'administracion-documentos',
    component: DocumentsAdministrationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchOrders'
})
export class OrdersFilterPipe implements PipeTransform {
    transform(value: any, searchValue): any {
        if (!searchValue) return value;
        return value.filter((v) => {
            if (v.user != undefined) {
                return (v.idOrder.toLowerCase().includes(searchValue.toLowerCase()))
                    ||
                    (v.user.name.toLowerCase().includes(searchValue.toLowerCase()))
                    ||
                    (v.user.firstName.toLowerCase().includes(searchValue.toLowerCase()))
                    ||
                    (v.user.lastName.toLowerCase().includes(searchValue.toLowerCase()))
                    ||
                    (v.user.email.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else {
                return (v.idOrder.toLowerCase().includes(searchValue.toLowerCase()))
                    ||
                    (v.name.toLowerCase().includes(searchValue.toLowerCase()))
            }
        }
        );
    }
}
import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class OrdersAdminUseCases {
    constructor(private reloadService:ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    edit(product) {
        this.postService.editData(product, Endpoints.orderPrivatePost);
    }

    updateStatus(idOrder,idStatus) {
        this.postService.editDataWithoutBody(`${Endpoints.orderStatusUpdate}?idOrder=${idOrder}&idStatus=${idStatus}`);
    }

    cancel(product){
        this.postService.addData(product,Endpoints.orderRefundPrivate);
    }

    delete(product){
        this.postService.deleteUsingBody(product,Endpoints.productsDelete);
    }

    retrieveOrders(beginDate,endDate) {
        var result = this.getService.retrieveData(`${Endpoints.orderPrivateGet}?beginDate=${beginDate}&endDate=${endDate}`);
        result.subscribe(
            (result) => this.setOrdersData(result)
        );
    }
    private setOrdersData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateOrders(res.data);
    }

    retrieveExcelOrders(beginDate,endDate,status) {
        var result = this.getService.retrieveData(`${Endpoints.orderExcelPrivate}?beginDate=${beginDate}&endDate=${endDate}&orderStatus=${status.status}`);
        result.subscribe(
            (result) => this.setExcelOrdersData(result)
        );
    }
    private setExcelOrdersData(res) {
        this.dataService.updateExcelOrders(res.data);
    }

    retrievePdfOrders(beginDate,endDate,status) {
        var result = this.getService.retrieveData(`${Endpoints.orderPDFPrivate}?beginDate=${beginDate}&endDate=${endDate}&orderStatus=${status.status}`);
        result.subscribe(
            (result) => this.setPdfOrdersData(result)
        );
    }
    private setPdfOrdersData(res) {
        this.dataService.updatePdfOrders(res.data);
    }

    retrieveOrderStatus() {
        var result = this.getService.retrieveData(Endpoints.orderStatusPrivate);
        result.subscribe(
            (result) => this.setOrderStatusData(result)
        );
    }
    private setOrderStatusData(res) {
        this.dataService.updateOrderStatus(res.data);
    }

    retrieveCategories() {
        var result = this.getService.retrieveData(Endpoints.categoriesPublic);
        result.subscribe(
            (result) => this.setCategoriesContentData(result)
        );
    }
    private setCategoriesContentData(res) {
        this.dataService.updateCategoriesList(res.data);
    }
}   
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyUseCases } from './use-cases/company-admin-use-cases';
import { DataService } from 'src/app/core/dataServices/data.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';
import { Observable, Subscription } from 'rxjs';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RegexService } from 'src/app/core/tools/regex.service';

@Component({
  selector: 'app-company-administration',
  templateUrl: './company-administration.component.html',
  styleUrls: ['./company-administration.component.scss'],
  providers: [CompanyUseCases],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        visibility: "visible"
      })),
      state('closed', style({
        opacity: 0,
        visibility: "hidden"
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class CompanyAdministrationComponent implements OnInit, OnDestroy {

  searchingCompany = false;
  companyData: Observable<any>;
  deliveryRestrictions: Observable<any>;

  showModal = false;
  showDeliveryRestrictionsModal = false;

  companyForm: FormGroup;
  deliveryRestrictionsForm: FormGroup;

  isEditDelivery = false;

  target = document.querySelector("body");

  getSub = new Subscription();
  editSub = new Subscription();

  constructor(private regex: RegexService, private builder: FormBuilder, private useCases: CompanyUseCases, private dataService: DataService, private reloadService: ReloadService) { }

  ngOnInit() {
    enableBodyScroll(this.target);
    this.setValidators();
    this.reloadData();

    this.useCases.retrieve();
    this.useCases.retrieveDeliveryRestrictions();
    this.searchingCompany = true;
    this.getCompanyData();
  }

  ngOnDestroy() {
    this.getSub.unsubscribe();
    this.editSub.unsubscribe();
  }

  reloadData() {
    this.getSub = this.reloadService.confirmationGetSuccess.subscribe(
      () => this.onGetSuccess()
    );

    this.editSub = this.reloadService.confirmationEditSuccess.subscribe(
      () => this.onEditSuccess()
    );
  }

  onGetSuccess() {
    this.searchingCompany = false;
  }

  onEditSuccess() {
    if (this.isEditDelivery) {
      this.toggleDeliveryRestrictionsModal(false);
      this.resetDeliveryRestrictionsForm();

      this.useCases.retrieveDeliveryRestrictions();

      this.isEditDelivery = false;

      return;
    }
    this.toggleModal(false);
    this.resetForm();

    this.searchingCompany = true;
    this.useCases.retrieve();
  }

  getCompanyData() {
    this.dataService.currentDeliveryRestrictions.subscribe((data) => console.log(data))
    this.companyData = this.dataService.currentCompanyData;
    this.deliveryRestrictions = this.dataService.currentDeliveryRestrictions;
  }

  toggleModal(isOpening, content?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showModal = !this.showModal;

      if (content != undefined) {
        this.setEditData(content);
      }

      return;
    }
    this.resetForm();
    enableBodyScroll(this.target);
    this.showModal = !this.showModal;
  }

  toggleDeliveryRestrictionsModal(isOpening, content?) {
    if (isOpening) {
      disableBodyScroll(this.target);
      this.showDeliveryRestrictionsModal = !this.showDeliveryRestrictionsModal;

      if (content != undefined) {
        this.setEditDeliveryRestrictionsData(content);
      }

      return;
    }
    this.resetForm();
    enableBodyScroll(this.target);
    this.showDeliveryRestrictionsModal = !this.showDeliveryRestrictionsModal;
  }

  setEditData(data) {
    this.companyForm.patchValue({
      id: data.id,
      address: data.address,
      description: data.description,
      mail: data.mail,
      socialMedia: data.socialMedia
    });

    var control = <FormArray>this.companyForm.controls['phones'];
    data.phones.forEach(element => {
      control.push(this.setPhoneElement(element));
    });
  }

  setEditDeliveryRestrictionsData(data) {
    this.deliveryRestrictionsForm.patchValue({
      id: data.id,
      deliveryArea: data.deliveryArea,
      minimumPurchase: data.minimumPurchase / 100,
      deliveryPrice: data.deliveryPrice / 100
    });
  }

  editCompanyData(form: FormGroup) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      this.isEditDelivery = false;
      this.useCases.edit(this.formatData(value));
    }
  }

  editDeliveryRestrictionsData(form: FormGroup) {
    var valid = form.valid;
    var value = form.value;

    if (valid) {
      this.isEditDelivery = true;
      this.useCases.editDeliveryRestrictions(this.formatDeliveryRestrictionsData(value));
    }
  }

  formatData(data) {
    var aux = [];
    data.phones.forEach(element => {
      aux.push(element.value)
    });

    data.phones = aux;

    return data;
  }

  formatDeliveryRestrictionsData(data) {
    var newObj = { ...data };
    newObj.minimumPurchase = data.minimumPurchase * 100;
    newObj.deliveryPrice = data.deliveryPrice * 100;

    return newObj;
  }

  resetForm() {
    this.companyForm.reset({
      id: "",
      address: "",
      description: "",
      mail: "",
      phones: "",
      socialMedia: ""
    });
  }

  resetDeliveryRestrictionsForm() {
    this.deliveryRestrictionsForm.reset({
      id: '',
      deliveryArea: '',
      minimumPurchase: '',
      deliveryPrice: ''
    });
  }

  setValidators() {
    this.companyForm = this.builder.group({
      id: [""],
      address: ["", Validators.compose([Validators.pattern(this.regex.addressRegex), Validators.required])],
      description: ["", Validators.compose([Validators.pattern(this.regex.longCommentsRegex), Validators.required])],
      mail: ["", Validators.compose([Validators.email, Validators.required])],
      phones: this.builder.array([]),
      socialMedia: [""]
    });

    this.deliveryRestrictionsForm = this.builder.group({
      id: [""],
      deliveryArea: [""],
      minimumPurchase: ["", Validators.compose([Validators.pattern(this.regex.currencyRegex), Validators.required])],
      deliveryPrice: ["", Validators.compose([Validators.pattern(this.regex.currencyRegex), Validators.required])],
    });
  }

  setPostalCodeElement(data) {
    return this.builder.group({
      value: [data, Validators.compose([Validators.required, Validators.pattern(this.regex.pcRegex)])]
    });
  }

  setPhoneElement(data) {
    return this.builder.group({
      value: [data, Validators.compose([Validators.required, Validators.pattern(this.regex.phonePattern)])]
    });
  }
}

import { User } from './user';

export class OnlineOrder {
    total: number;
    timestamp: number;
    orderProducts: Array<any>;
    deliveryDate: number;
    user: User;
    name?:string;
    address: string;
    distance: number;
    deliveryCost:number;
}
import { Injectable } from '@angular/core';
import { GetService } from 'src/app/core/httpServices/get.service';
import { DataService } from 'src/app/core/dataServices/data.service';
import { Endpoints } from 'src/app/core/enums/endpoints';
import { PostService } from 'src/app/core/httpServices/post.service';
import { ReloadService } from 'src/app/core/dataServices/reload.service';

@Injectable()
export class ProductsAdminUseCases {
    constructor(private reloadService:ReloadService, private getService: GetService, private dataService: DataService, private postService: PostService) { }

    add(product) {
        this.postService.addData(product, Endpoints.productsPrivate);
    }

    edit(product) {
        this.postService.editData(product, Endpoints.productsPrivate);
    }

    delete(product){
        this.postService.deleteUsingBody(product,Endpoints.productsDelete);
    }

    disable(product){
        this.postService.disableData(product,Endpoints.productsPrivateVisibility);
    }

    retrieveProducts() {
        var result = this.getService.retrieveData(Endpoints.productsPrivate);
        result.subscribe(
            (result) => this.setProductsContentData(result)
        );
    }
    private setProductsContentData(res) {
        this.reloadService.confirmGet();
        this.dataService.updateProductsList(res.data);
    }

    retrieveCategories() {
        var result = this.getService.retrieveData(Endpoints.categoriesPublic);
        result.subscribe(
            (result) => this.setCategoriesContentData(result)
        );
    }
    private setCategoriesContentData(res) {
        this.dataService.updateCategoriesList(res.data);
    }
}   